import {appInstance} from './http';
import errorHandler from "./errorHandler";
import {buildQuery} from "./buildQuery";


export const getFoodCategories = async({lang}) => {
  try {
    const {data} = await appInstance({lang}).get(`/food-category?pagination=${0}`);
    return data;
  } catch(error) {
    throw errorHandler(error);
  }
}

export const getFoodCategoriesDd = async({lang}) => {
  try {
    const {data} = await appInstance({lang}).get(`/food-category/get-food-dd`);
    return data;
  } catch(error) {
    throw errorHandler(error);
  }
}
