import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import {Stack, TableCell, TableHead} from "@mui/material";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import AppLogo from "../../../assets/images/logo.svg";
import MDTypography from "../../../components/MDTypography";
import Grid from "@mui/material/Grid";
import MDButton from "../../../components/MDButton";
import {Block, CloseSharp, PrintSharp} from "@mui/icons-material";
import {convertToUtcWithoutTime} from "../../../utils/timeConvert";
import styled from "styled-components";


const Dashed = styled.div`
  border-top: 1px dashed #29aabb;
`;

const SmallBlueCircle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid #29aabb;
  margin-right: 10px;
`;

export const InvoiceDesing = ({selectedOrder, closeModal,}) => {
  const {billing_info, summary, order_detail, booking} = selectedOrder;

  console.log("selectedOrder", selectedOrder)
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} textAlign="right">
        <MDButton sx={{mr: 1}} iconOnly={true} color="dark" size="small" onClick={() => window.print()}><PrintSharp/></MDButton>
        <MDButton iconOnly={true} color="info" size="small" onClick={closeModal}><CloseSharp/></MDButton>
      </Grid>
      <Grid item xs={12}>
        <img src={AppLogo} alt="logo"/>
      </Grid>
      <Grid item xs={8}>
        <MDTypography fontSize={14}>#{selectedOrder.order_no}</MDTypography>
      </Grid>
      <Grid item xs={4}>
        <Stack sx={{marginLeft: "auto"}}>
          <MDTypography sx={{marginLeft: "auto"}} fontSize={14}>{convertToUtcWithoutTime(selectedOrder.updated_at)}</MDTypography>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Dashed/>
      </Grid>
      <Grid item xs={12}>
        <MDTypography fontSize={15} fontWeight="bold">Summary</MDTypography>
        {summary.map((item, index) => (
          <Stack flexDirection="row" alignItems="center">
            <SmallBlueCircle/>
            <MDTypography key={index} fontSize={13}>{`${item}`}</MDTypography>
          </Stack>
        ))}
      </Grid>
      <Grid item xs={12}>
        <Dashed/>
      </Grid>

      <Grid item xs={12}>
        <MDTypography fontSize={15} fontWeight="bold">Guest</MDTypography>
        {order_detail.map((item, index) => (
          <Stack>
            <MDTypography key={index} fontSize={13} fontWeight="bold">{`${item.name_surname || "-"}`}</MDTypography>
            {item.cocktail_food &&
              <Stack flexDirection="row" alignItems="center">
                <SmallBlueCircle/>
                <MDTypography key={index} fontSize={13}>{`${item.cocktail_food.name}`}</MDTypography>
              </Stack>}
            {item.main_food &&
              <Stack flexDirection="row" alignItems="center">
                <SmallBlueCircle/>
                <MDTypography key={index} fontSize={13}>{`${item.main_food.name}`}</MDTypography>
              </Stack>}
            {item.salad_food &&
              <Stack flexDirection="row" alignItems="center">
                <SmallBlueCircle/>
                <MDTypography key={index} fontSize={13}>{`${item.salad_food.name}`}</MDTypography>
              </Stack>}
            {item.starter_food &&
              <Stack flexDirection="row" alignItems="center">
                <SmallBlueCircle/>
                <MDTypography key={index} fontSize={13}>{`${item.starter_food.name}`}</MDTypography>
              </Stack>}
            {item.dessert_food &&
              <Stack flexDirection="row" alignItems="center">
                <SmallBlueCircle/>
                <MDTypography key={index} fontSize={13}>{`${item.dessert_food.name}`}</MDTypography>
              </Stack>}
          </Stack>
        ))}
      </Grid>

      <Grid item xs={12}>
        <Dashed/>
      </Grid>

      <Grid item xs={12}>
        <MDTypography fontSize={13}>{`${billing_info.address}`}</MDTypography>
        <MDTypography fontSize={13}>{` ${billing_info.city} ${billing_info.state}`}</MDTypography>
        <MDTypography fontSize={13}>{`${billing_info.country}`}</MDTypography>
        <MDTypography fontSize={13}>{`${billing_info.zip_code}`}</MDTypography>
      </Grid>
      <Grid item xs={12}>
        <Dashed/>
      </Grid>

      <Grid item xs={12}>
        <MDTypography fontSize={13}>{`${billing_info.name} ${billing_info.surname}`}</MDTypography>
        <MDTypography fontSize={13}>{`${billing_info.email} `}</MDTypography>
        <MDTypography fontSize={13}>{`${billing_info.phone} `}</MDTypography>
      </Grid>

      <Grid item xs={12}>
        <Dashed/>
      </Grid>
      {/*      <Grid item xs={6} md={6}>
       <MDTypography fontSize={15} fontWeight="bold">Summary</MDTypography>
       {summary.map((item, index) => (
       <Stack flexDirection="row" alignItems="center">
       <SmallBlueCircle/>
       <MDTypography key={index} fontSize={13}>{`${item}`}</MDTypography>
       </Stack>
       ))}
       </Grid>
       <Grid item xs={2} md={2}>
       <MDTypography fontSize={15} fontWeight="bold">Unit</MDTypography>
       <MDTypography fontSize={13}>{selectedOrder.unit}</MDTypography>
       </Grid>
       <Grid item xs={2} md={2}>
       <MDTypography fontSize={15} fontWeight="bold">Unit price</MDTypography>
       <MDTypography fontSize={13}>{selectedOrder.unit_price}</MDTypography>
       </Grid>
       <Grid item xs={2} md={2}>
       <MDTypography fontSize={15} fontWeight="bold">Total Price</MDTypography>
       <MDTypography fontSize={13}>{selectedOrder.unit * selectedOrder.unit_price}</MDTypography>
       </Grid>
       <Grid item xs={12}>
       <Dashed/>
       </Grid>*/}
      <Grid item xs={6}></Grid>
      <Grid item xs={6}>
        <Stack flexDirection="row">
          <MDTypography fontSize={15} fontWeight="bold">{`${selectedOrder.unit} x ${booking.name} `}</MDTypography>
          <MDTypography sx={{marginLeft: "auto"}} fontSize={13}>€{selectedOrder.unit_price}</MDTypography>
        </Stack>
        <Stack flexDirection="row">
          <MDTypography fontSize={15} fontWeight="bold">{`Total`}</MDTypography>
          <MDTypography sx={{marginLeft: "auto"}} fontSize={13}>€{selectedOrder.unit * selectedOrder.unit_price}</MDTypography>
        </Stack>
        <Stack flexDirection="row" mt={-1}>
          <MDTypography sx={{marginLeft: "auto"}} fontSize={10}>Tax Included</MDTypography>
        </Stack>
        <Stack flexDirection="row">
          <MDTypography fontSize={15} fontWeight="bold">Exchange Rate</MDTypography>
          <MDTypography sx={{marginLeft: "auto"}} fontSize={13}>{`1EUR = ${selectedOrder.currency.toFixed(2)}TRY`}</MDTypography>
        </Stack>
        <Stack flexDirection="row">
          <MDTypography fontSize={15} fontWeight="bold">Total(TRY)</MDTypography>
          <MDTypography sx={{marginLeft: "auto"}} fontSize={13}>₺{selectedOrder.paid_price}</MDTypography>
        </Stack>
        <Dashed/>
        <Stack flexDirection="row" sx={{marginY: 1}}>
          <MDTypography color="info" fontSize={16} fontWeight="bold">Total Paid</MDTypography>
          <MDTypography
            color="info" sx={{marginLeft: "auto"}}
            fontWeight="bold"
            fontSize={16}>
            ₺{selectedOrder.paid_price}
          </MDTypography>
        </Stack>
        <Dashed/>
      </Grid>
    </Grid>
  );
}
