import {appInstance} from './http';
import errorHandler from "./errorHandler";
import {buildQuery} from "./buildQuery";


export const getBookings = async({limit, page, lang, is_crud = 0, pagination = 1}) => {
  const query = buildQuery({limit, page, is_crud, pagination});
  const queryParams = new URLSearchParams(query).toString();
  try {
    const {data} = await appInstance({lang}).get(`/booking?${queryParams}`);
    return data;
  } catch(error) {
    throw errorHandler(error);
  }
}

export const getRezervationBookings = async(lang) => {
  try {
    const {data} = await appInstance({lang}).get(`/booking`);
    return data;
  } catch(error) {
    throw errorHandler(error);
  }
}

export const getBooking = async({id, lang}) => {
  try {
    const {data} = await appInstance({lang}).get(`/booking/${id}`);
    return data;
  } catch(error) {
    throw errorHandler(error);
  }
}

export const createBooking = async({
  name,
  image,
  price,
  start_date,
  end_date,
  booking_type,
  cocktail_food,
  dessert_food,
  main_food,
  starter_food,
  salad_food,
  total_stock,
  online_stock,
  lang
}) => {
  const payload = {
    name,
    image,
    price,
    start_date,
    end_date,
    booking_type,
    cocktail_food,
    dessert_food,
    main_food,
    starter_food,
    salad_food,
    total_stock,
    online_stock,
  }
  try {
    const {data} = await appInstance({lang}).post(`/booking`, payload);
    return data;
  } catch(error) {
    throw errorHandler(error);
  }
}

export const updateBooking = async({
  id,
  name,
  image,
  price,
  start_date,
  end_date,
  booking_type,
  cocktail_food,
  dessert_food,
  main_food,
  starter_food,
  salad_food,
  total_stock,
  online_stock,
  lang
}) => {
  const payload = {
    name,
    image,
    price,
    start_date,
    end_date,
    booking_type,
    cocktail_food,
    dessert_food,
    main_food,
    starter_food,
    salad_food,
    total_stock,
    online_stock,
  }
  try {
    const {data} = await appInstance({lang}).put(`/booking/${id}`, payload);
    return data;
  } catch(error) {
    throw errorHandler(error);
  }
}

export const removeBooking = async({id, lang}) => {
  try {
    const {data} = await appInstance({lang}).delete(`/booking/${id}`);
    return data;
  } catch(error) {
    throw errorHandler(error);
  }
}

export const bookingOnDay = async({lang, today}) => {
  const query = {today: today}
  const queryParams = new URLSearchParams(query).toString();
  try {
    const {data} = await appInstance({lang}).get(`/booking?${queryParams}`);
    return data;
  } catch(error) {
    throw errorHandler(error);
  }
};

export const bookingCalendar = async({lang, today}) => {
  const query = {today: today}
  const queryParams = new URLSearchParams(query).toString();
  try {
    const {data} = await appInstance(lang).get(`/booking/calendar?${queryParams}`);
    return data;
  } catch(error) {
    throw errorHandler(error);
  }
};
