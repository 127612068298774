import {appInstance} from './http';
import errorHandler from "./errorHandler";


export const getRequestFormStates = async({lang}) => {
  try {
    const {data} = await appInstance({lang}).get(`/reservation-request-form-state`);
    return data;
  } catch(error) {
    throw errorHandler(error);
  }
}

export const updateRequestFormState = async({lang, id, reservation_request_form_state}) => {
  const payload = {
    reservation_request_form_state
  }
  try {
    const {data} = await appInstance({lang}).put(`/reservation-request-form/${id}`, payload);
    return data;
  } catch(error) {
    throw errorHandler(error);
  }
}
