import React, {useContext, useState} from "react";

// 3rd libraries
import {useFormik} from "formik";
import {toast} from "react-toastify";

// @mui
import Grid from "@mui/material/Grid";

//components
import MDTypography from "../../../components/MDTypography";
import MDButton from "../../../components/MDButton";
import {Items} from "../../request-form/components/items";

// services
import {removeContactForm} from "../../../services/contactFormService";

// context
import {LanguageContext} from "../../../context/Language";


export const UpdateContactForm = ({selectedContactForm, closeModal, fetchData}) => {
  const [loading, setLoading] = useState(false);

  const {t, language} = useContext(LanguageContext);

  const formik = useFormik({
    initialValues: {
      id: selectedContactForm.id,
    },
    onSubmit: async(values) => {
      try {
        await removeContactForm({id: selectedContactForm.id});
        toast.success(t("contactForm.update.success"));
        closeModal?.();
        fetchData?.()
      } catch(error) {
        console.log(error)
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <MDTypography
            ml={2}
            color="info"
            fontWeight="bold"
            mt={2}>
            {t("contactForm.update.title")}
          </MDTypography>
        </Grid>
        <Grid item xs={12} md={12}>
          <Items name={t("items.user")} value={`${selectedContactForm.name} ${selectedContactForm.surname}`}/>
          <Items name={t("items.email")} value={`${selectedContactForm.email} `}/>
        </Grid>
        <Grid item xs={12} md={12} mt={-2}>
          <MDTypography fontWeight="bold" fontSize={15}>{t("message")}</MDTypography>
          <MDTypography fontSize={14} color="text">{selectedContactForm.comment}</MDTypography>
        </Grid>
        <Grid item container xs={12} spacing={2}>
          {selectedContactForm.is_answered && (
            <Grid item xs={12} md={12}>
              <MDButton
                disabled={loading}
                variant="outlined"
                color="error"
                fullWidth
                onClick={closeModal}
              >
                {t("buttons.cancel")}
              </MDButton>
            </Grid>
          )}
          {!selectedContactForm.is_answered && (
            <>
              <Grid item xs={7} md={8}>
                <MDButton
                  disabled={loading}
                  variant="contained"
                  color="info"
                  fullWidth
                  type="submit"
                >
                  {t("buttons.is_read")}
                </MDButton>
              </Grid>
              <Grid item xs={5} md={4}>
                <MDButton
                  disabled={loading}
                  variant="outlined"
                  color="error"
                  fullWidth
                  onClick={closeModal}
                >
                  {t("buttons.cancel")}
                </MDButton>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </form>
  )
}
