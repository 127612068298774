import MDTypography from "../../../components/MDTypography";
import Tooltip from "@mui/material/Tooltip";
import MDButton from "../../../components/MDButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {convertToDate, convertToTimeUTC} from "../../../utils/timeConvert";
import MDBox from "../../../components/MDBox";
// import image from "../../../assets/images/default-blog.png";
import {Stack} from "@mui/material";



export const columns = ({handleConfirm, updateBrand, t}) => {

  return [
    {
      Header: t("gallery.tableHeader.file"),
      accessor: "file",
      align: "left",
      Cell: ({row}) => {
        const fileData = row.original.file_type.split("/")[1];
        if (fileData === "video") {
          return (
            <MDBox
              component="video"
              src={row.original.file}
              alt="brand"
              sx={{
                width: 100,
                height: 50,
                borderRadius: 1,
                objectFit: "cover",
              }}
            />
          );
        } else {
          return (
            <MDBox
              component="img"
              src={row.original.file}
              alt="brand"
              sx={{
                width: 100,
                height: 50,
                borderRadius: 1,
                objectFit: "cover",
              }}
            />
          );
        }
      },
    },
    {
      Header: t("gallery.tableHeader.type"),
      accessor: "type",
      align: "left",
      width: "100%",
      Cell: ({row}) => (
          <MDTypography variant="title1" fontWeight="medium">
            {row.original.file_type}
          </MDTypography>
      ),
    },
    {
      Header: t("gallery.tableHeader.createdAt"),
      accessor: "createdAt",
      align: "left",
      Cell: ({row}) => (
        <Stack flexDirection="column">
          <MDTypography variant="title1" fontWeight="medium">
            {convertToDate(row.original.createdAt)}
          </MDTypography>
          <MDTypography color="inherit" variant="caption" fontSize={12}>
            {convertToTimeUTC(row.original.createdAt)}
          </MDTypography>
        </Stack>
      ),
    },
    {
      Header: t("gallery.tableHeader.actions"),
      accessor: "actions",
      align: "left",
      isSticky: true,
      Cell: ({row}) => (
        <Tooltip title={t("gallery.tableHeader.delete")}>
          <MDButton
            size="small"
            sx={{maxWidth: 30, minWidth: 30, width: 30}}
            color="error"
            onClick={() => handleConfirm(row.original.id)}
          >
            <DeleteIcon/>
          </MDButton>
        </Tooltip>
      ),
    },
  ];
};
