import {appInstance} from "./http";
import errorHandler from "./errorHandler";


export const awsUploadS3 = async({lang, file}) => {
  const formData = new FormData();
  formData.append("file", file);
  try {
    const {data} = await appInstance({lang}).post("/aws/upload-s3", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return data;
  } catch(error) {
    throw errorHandler(error);
  }
};
