import MDTypography from "../../../components/MDTypography";
import Tooltip from "@mui/material/Tooltip";
import MDButton from "../../../components/MDButton";
import {convertToDate, convertToTimeUTC, convertToUtcWithoutTime} from "../../../utils/timeConvert";
import {Date} from "./Date";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {Status} from "./items";
import {Block} from "@mui/icons-material";


export const columns = ({openDetail, handleConfirm ,t }) => {

  return [
    {
      Header: t("order.tableHeader.name"),
      accessor: "id",
      align: "left",
      Cell: ({row}) => (
        <MDTypography variant="title1" fontWeight="medium">
          {`${row.original.billing_info.name} ${row.original.billing_info.surname}`}
        </MDTypography>
      ),
    },
    {
      Header: t("order.tableHeader.phone"),
      accessor: "phone",
      align: "left",
      Cell: ({row}) => (
        <MDTypography variant="title1" fontWeight="medium">
          {row.original.billing_info.phone}
        </MDTypography>
      ),
    },
    {
      Header: t("order.tableHeader.order_no"),
      accessor: "order_no",
      align: "left",
      Cell: ({row}) => (
        <MDTypography variant="title1" fontWeight="medium">
          {row.original.order_no}
        </MDTypography>
      ),
    },
    {
      Header: t("order.tableHeader.booking_name"),
      accessor: "name",
      align: "left",
      cWidth: "70px",
      Cell: ({row}) => (
        <MDTypography variant="title1" fontWeight="medium">
          {row.original.booking.name}
        </MDTypography>
      ),
    },

    {
      Header: t("order.tableHeader.unit"),
      accessor: "unit",
      align: "center",
      cWidth: "70px",
      Cell: ({row}) => (
        <MDTypography variant="title1" fontWeight="medium">
          {row.original.unit}
        </MDTypography>
      ),
    },
    {
      Header: t("order.tableHeader.booking_price"),
      accessor: "unit_price",
      align: "center",
      cWidth: "70px",
      Cell: ({row}) => (
        <MDTypography variant="title1" fontWeight="bold">{`€${row.original.unit_price}`}</MDTypography>
      ),
    },
    {
      Header: t("order.tableHeader.rate"),
      accessor: "currency",
      align: "center",
      cWidth: "70px",
      Cell: ({row}) => (
        <MDTypography
          variant="title1"
          fontWeight="bold">
          {row.original.currency.toFixed(2)}
        </MDTypography>
      ),
    },
    {
      Header: t("order.tableHeader.total_price"),
      accessor: "paid_price",
      align: "left",
      Cell: ({row}) => (
        <MDTypography
          variant="title1"
          fontWeight="bold">
          {`${(row.original.paid_price || 0)} ${row.original.currency_type}`}
        </MDTypography>
      ),
    },
    {
      Header: t("order.tableHeader.status"),
      accessor: "order_state",
      align: "center",
      Cell: ({row}) => (
        <Status status={row.original.order_state.name_slug}/>
      ),
    },
    {
      Header: t("order.tableHeader.created_at"),
      accessor: "created_at",
      align: "left",
      Cell: ({row}) => (
        <Date
          start={`${convertToUtcWithoutTime(row.original.created_at)}`}
          end={`${convertToTimeUTC(row.original.created_at)}`}
        />
      ),
    },
    {
      Header: t("booking.tableHeader.actions"),
      accessor: "actions",
      align: "left",
      isSticky: true,
      Cell: ({row}) => (
        <>
          <Tooltip title={t("buttons.detail")}>
            <MDButton
              size="small"
              sx={{marginRight: 1, maxWidth: 30, minWidth: 30, width: 30}}
              color="dark"
              onClick={() => openDetail(row.original)}
            >
              <VisibilityIcon/>
            </MDButton>
          </Tooltip>
          {row.original.order_state.name_slug !== "cancel" && (
            <Tooltip title={t("buttons.cancel")}>
              <MDButton
                size="small"
                sx={{maxWidth: 30, minWidth: 30, width: 30}}
                color="error"
                onClick={() => handleConfirm(row.original.id)}
              >
                <Block/>
              </MDButton>
            </Tooltip>
          )}
        </>
      ),
    },
  ];
};
