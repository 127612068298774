import {useContext, useRef, useState} from "react";

//libraries
import {toast} from "react-toastify";

// components
import DashboardLayout from "../../components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../components/Navbars/DashboardNavbar";
import NewTable from "../../components/Tables1/Table";

// hook
import {useFetchData} from "../../hooks/useFetchData";

//services

//data
import {columns} from "./components/columns";

// modals
// import { AddBrand } from "./add";
// import { UpdateBrand } from "./update";
// import { ConfirmModal } from "../../components/Modal/confirm/confirm";

// utils
import {getsGallery, removeGallery} from "../../services/galleryService";
import Modal from "../../components/Modal";
import {AddAssets} from "./add";
import {LanguageContext} from "../../context/Language";
import {ConfirmModal} from "../../components/Modal/confirm/confirm";
import LoadingComponent from "../../components/LoadingComponent";


export const Gallery = () => {
  const [modal, setModal] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedAssetId, setSelectedAssetId] = useState(null);
  const addAssetRef = useRef(null);
  const updateAssetRef = useRef(null);
  const deleteAssetRef = useRef(null);

  const is_crud = 1;

  const {t, language} = useContext(LanguageContext);

  console.log("language", language.code)

  const {
    loading,
    data,
    setData,
    limit,
    setLimit,
    setPage,
    page,
    maxPage,
    fetchData,
  } = useFetchData(getsGallery, [], language.code, is_crud);


  const addAssetModal = () => {
    addAssetRef.current.open();
    setModal(true);
  };
  const handleConfirm = (id) => {
    deleteAssetRef.current.open();
    console.log(id)
    setSelectedAssetId(id);
  };
  const updateImageGallery = (currentAsset) => {
    setSelectedBrand(currentAsset);
    updateAssetRef.current.open();
  };

  const deleteAsset = async () => {
    try {
      await removeGallery({id: selectedAssetId});
      const assetIndex = data.findIndex(
        (brand) => brand.id === selectedAssetId
      );
      const updatedAsset = [...data];
      updatedAsset.splice(assetIndex, 1);
      setData(updatedAsset);
      toast.success(t("gallery.delete.success"));
    } catch (error) {
      console.log(error)
      toast.error(error.message);
    }
  };

  if (loading) return <LoadingComponent />;

  return (
    <DashboardLayout>
      <DashboardNavbar/>
      <Modal
        ref={addAssetRef}
        closed={modal}
        children={<AddAssets fetchData={() => fetchData()} closeModal={() => setModal((prev) => !prev)}/>}
        customStyle={{
          width: {xs: "90%", md: "40%"},
          maxHeight: "90vh",
          overflow: "auto",
        }}
      />
      <Modal
        ref={deleteAssetRef}
        children={
          <ConfirmModal
            closeModal={deleteAssetRef.current?.close}
            deleteFunc={() => deleteAsset()}
            title={t("gallery.delete.message")}
          />
        }
      />
      <NewTable
        title="Gallery"
        limit={limit}
        setLimit={setLimit}
        setPage={setPage}
        page={page}
        maxPage={maxPage}
        prevFunc={() => setPage(page - 1)}
        nextFunc={() => setPage(page + 1)}
        columns={columns({handleConfirm, updateImageGallery, t})}
        // search={search}
        // setSearch={setSearch}
        // canSearch={true}
        rows={data}
        addFunc={addAssetModal}
      />
    </DashboardLayout>
  );
};
