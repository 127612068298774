import {appInstance} from "./http";
import errorHandler from "./errorHandler";


const id = Math.random();
export const authLogin = async(payload) => {
  try {
    const headers = {
      "x-panel": "admin",
    };
    const {data} = await appInstance({ lang: payload.lang}).post(`auth/login`, {email: payload.email, password: payload.password, device_uuid: id.toString()}, {headers});
    return data;
  } catch(error) {
    throw errorHandler(error);
  }
};

