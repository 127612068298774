import Select from "react-select";
import {styles} from "./styles";
import React, {useContext} from "react";
import {LanguageContext} from "../../../context/Language";


export const FoodSelect = ({value, onChange, options, placeholder, inputVal }) => {

  const {t} = useContext(LanguageContext);

  return (
    <Select
      fullWidth
      placeholder={placeholder}
      styles={styles}
      isMulti
      isClearable={true}
      noOptionsMessage={({inputValue}) =>
        `${inputValue || inputVal} ${t("foods.inputs.notfound")}.`
      }
      value={value}
      onChange={onChange}
      options={options?.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      })}
    />
  )
}
