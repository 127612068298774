import {
  Autocomplete,
  Card,
  CircularProgress,
  IconButton,
  Pagination,
  Stack, TablePagination,
  Tooltip,
} from "@mui/material";
import React, {useContext, useState} from "react";
import MDBox from "../MDBox";
import MDInput from "../MDInput";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MDTypography from "../MDTypography";
import DataTable from "./DataTable";
import MDButton from "../MDButton";
import {AddCircleOutlined} from "@mui/icons-material";
import {LanguageContext} from "../../context/Language";
import Select from "react-select";
import {customStyles} from "./style";



function NewTable({
  title = "Projects",
  limit = 5,
  setLimit,
  setPage,
  page = 1,
  maxPage = 1,
  customMenu = [],
  filter,
  setFilter,
  options,
  optionsValue,
  columns,
  isFilter = false,
  addFunc,
  rows,
  isLoading,
  canSearch,
  search,
  setSearch,
}){
  const [menu, setMenu] = useState(null);
  const {t} = useContext(LanguageContext);
  const openMenu = ({currentTarget}) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);

  const entries = ["5", "10", "15", "20", "25", "30"];

  const handleMenuClick = (func) => {
    func?.();
    closeMenu();
  };



  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      {customMenu?.map((e, idx) => (
        <MenuItem key={`menu_${idx}`} onClick={() => handleMenuClick(e.onClick)}>
          {e.name}
        </MenuItem>
      ))}
    </Menu>
  )

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleChangeFilter = (e) => {
    setFilter(e.value);
  };

  return (
    <Card>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={3}
        pb={1}
      >
        <MDBox
          sx={{display: "flex", flexDirection: "row", alignItems: "center"}}
        >
          <MDTypography mr={3} variant="h6" gutterBottom>
            {title}
          </MDTypography>
        </MDBox>
        <MDBox color="text" sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
          {isFilter && (
            <Select
              fullWidth
              options={options}
              styles={customStyles}
              placeholder="Filter"
              value={options.find((option) => option.value === filter)}
              onChange={handleChangeFilter}
            />
          )}
          {canSearch && (
            <MDBox width="10rem" ml={2}>
              <MDInput
                placeholder="Ara..."
                value={search}
                size="small"
                fullWidth
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </MDBox>
          )}
          {!!addFunc && (
            <>
            <Tooltip title={name}>
              <MDButton
                size="small"
                sx={{ maxWidth: 50, minWidth: 50, width: 50 }}
                color="info"
                onClick={addFunc}
              >
                <AddCircleOutlined />
              </MDButton>
            </Tooltip>
            </>
            )}
          {/*<MDBox color="text" px={2}>*/}
          {/*  <MDButton*/}
          {/*    sx={{cursor: "pointer", fontWeight: "bold"}}*/}
          {/*    color="success"*/}
          {/*    onClick={openMenu}*/}
          {/*  >*/}
          {/*    İşlemler*/}
          {/*  </MDButton>*/}
          {/*</MDBox>*/}
          {/*{renderMenu}*/}
        </MDBox>
      </MDBox>

      <MDBox>
        {rows?.length ? (
          <DataTable
            table={{columns, rows}}
            showTotalEntries={false}
            isSorted={false}
            entriesPerPage={{defaultValue: limit}}
          />
        ) : (
          <Stack
            sx={{
              padding: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {isLoading ? (
              <CircularProgress color="info"/>
            ) : (
              <MDTypography fontSize={20} color="info">
                {t("data.noData")}
              </MDTypography>
            )}
          </Stack>
        )}

        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={3}

        >
          <MDBox display="flex" alignItems="center">
            <Autocomplete
              disableClearable
              value={limit.toString()}
              options={entries}
              onChange={(event, newValue) => {
                setPage(1);
                setLimit(parseInt(newValue, 10));
              }}
              size="small"
              sx={{width: "5rem"}}
              renderInput={(params) => <MDInput {...params} />}
            />
            <MDTypography variant="caption" color="secondary">
              &nbsp;&nbsp;{t("entries")}
            </MDTypography>
          </MDBox>
          <MDBox pb={2}>
            <Pagination
              size="small"
              onChange={handleChange}
              page={page}
              count={Math.round(maxPage) < 1 ? 1 : Math.round(maxPage)}
              showFirstButton
              showLastButton
              shape="rounded"
              color="info"
              siblingCount={0}
              boundaryCount={2}
            />
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default NewTable;
