import React, {useContext, useEffect, useState} from "react";

// 3rd libraries
import Select from "react-select";
import {useFormik} from "formik";
import {toast} from "react-toastify";

// @mui

import Grid from "@mui/material/Grid";

//components
import MDInput from "../../../components/MDInput";
import MDTypography from "../../../components/MDTypography";
import MDButton from "../../../components/MDButton";
import {styles} from "../components/styles";

// services
import {createRequestForm} from "../../../services/requestFormService";

// context
import {LanguageContext} from "../../../context/Language";


export const CreateRequestForm = ({closeModal, fetchData}) => {
  const [loading, setLoading] = useState(false);
  const [requestFormCategory, setRequestFormCategory] = useState([]);

  const {t} = useContext(LanguageContext);

  const formik = useFormik({
    initialValues: {
      name: {tr: "", en: ""},
      requestFormCategory: [],
    },
    onSubmit: async(values) => {
      const payload = {
        name: {tr: values.name.tr, en: values.name.en},
        requestForm_category: values.requestFormCategory.value
      };
      try {
        await createRequestForm(payload);
        toast.success(t("requestForms.create.success"));
        closeModal?.();
        fetchData?.()
      } catch(error) {
        console.log(error)
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid xs={12}><MDTypography ml={2} color="info" fontWeight="bold">{t("requestForms.create.title")}</MDTypography></Grid>
        <Grid item xs={12} md={12}>
          <MDInput
            fullWidth
            label={t("requestForms.inputs.name.tr")}
            name="name.tr"
            value={formik.values.name.tr}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <MDInput
            fullWidth
            label={t("requestForms.inputs.name.en")}
            name="name.en"
            value={formik.values.name.en}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Select
            fullWidth
            placeholder={t("requestForms.inputs.requestFormCategory")}
            styles={styles}
            isClearable={true}
            noOptionsMessage={({inputValue}) =>
              `${inputValue || t("requestForms.inputs.requestForm")} ${t("requestForms.inputs.notfound")}.`
            }
            value={formik.values.requestFormCategory}
            onChange={(value) => formik.setFieldValue("requestFormCategory", value)}
            options={requestFormCategory?.map((item) => {
              return {
                label: item.name,
                value: item.id,
              };
            })}
          />
        </Grid>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={7} md={8}>
            <MDButton
              disabled={loading}
              variant="contained"
              color="info"
              fullWidth
              type="submit"
            >
              {t("buttons.save")}
            </MDButton>
          </Grid>
          <Grid item xs={5} md={4}>
            <MDButton
              disabled={loading}
              variant="outlined"
              color="error"
              fullWidth
              onClick={closeModal}
            >
              {t("buttons.cancel")}
            </MDButton>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}
