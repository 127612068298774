import axios from "axios";

export const BASE_API = process.env.REACT_APP_API_URL;

const defaultLang = 'en';

export const checkTokens = async(config) => {
  const tokens = JSON.parse(localStorage.getItem("token"));
  config.headers.Authorization = "Bearer " + tokens;
  return config;
};

export const appInstance = ({ lang = defaultLang }) => {
  const _appInstance = axios.create({
    baseURL: `${BASE_API}/api/v1/${lang}`
  });
  _appInstance.interceptors.request.use(checkTokens);
  return _appInstance;
};
