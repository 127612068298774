import {appInstance} from "./http";
import errorHandler from "./errorHandler";
import {buildQuery} from "./buildQuery";

export const getContactForm = async({limit, page, lang, pagination = 1, filter = 1}) => {
  const query = buildQuery({limit, page, pagination, is_answered: filter});
  const queryParams = new URLSearchParams(query).toString();
  try {
    const {data} = await appInstance({lang}).get(`/contact-form?${queryParams}`);
    return data;
  } catch(error) {
    throw errorHandler(error);
  }
}

export const removeContactForm = async({lang, id}) => {
  try {
    const {data} = await appInstance({lang}).patch(`/contact-form/${id}`);
    return data;
  } catch(error) {
    throw errorHandler(error);
  }
}
