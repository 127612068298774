import {appInstance} from './http';
import errorHandler from "./errorHandler";

export const getOrderStates = async({lang}) => {
  try {
    const {data} = await appInstance({lang}).get(`/order-state`);
    return data;
  } catch(error) {
    throw errorHandler(error);
  }
}
