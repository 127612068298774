import moment from "moment/moment";
import dayjs from "dayjs";

import "moment/locale/tr";
import {useContext} from "react";
import {LanguageContext} from "../context/Language";


export function convertToTimeUTC(date) {
  const {language} = useContext(LanguageContext);
  return moment(date).utc().locale(language.code).format("HH:mm");
}

/*20 February 2023 Monday | 00:00*/
export const convertToUtcLongDate = (date) => {
  const {language} = useContext(LanguageContext);
  return moment(date).utc().locale(language.code).format("DD MMMM YYYY dddd | HH:mm");
};

/*20 February 2023 Monday */
export const convertToUtcWithoutTime = (date) => {
  const {language} = useContext(LanguageContext);
  return moment(date).utc().locale(language.code).format("DD MMMM YYYY dddd");
};

export const convertToDate = (date) => {
  const {language} = useContext(LanguageContext);
  return moment(date).utc().locale(language.code).format("DD-MM-YYYY");
};

export const convertToDateTime = (date) => {
  const {language} = useContext(LanguageContext);
  return moment(date).utc().locale(language.code).format("YYYY-MM-DD HH:mm:ss");
};

export const convertToFullDate = (date) => {
  return dayjs(date).format("YYYY-MM-DD HH:mm:ss")
}
