import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {FileContent, PreviewWrapper, ImageSrc, PreviewContainerBox, VideoSrc} from "./style";
import { Skeleton } from "@mui/material";
import MDBox from "../MDBox";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {PlayArrowSharp} from "@mui/icons-material";


const VideoPreviewContainer = ({
  fileList,
  editList,
  imgStyle,
  loading,
  width,
  height,
}) => {
  const [isDragging, setDragging] = React.useState(false);

  const removeFile = (file) => {
    const fileListCopy = [...fileList];
    const index = fileListCopy.indexOf(file);
    fileListCopy.splice(index, 1);
    editList?.(fileListCopy);
    console.log("remove file", fileList);
  };



  if (!Array.isArray(fileList) || fileList.length === 0) {
    return (
      <PreviewWrapper>
        <p>Dosya yok</p>
        <div
          style={{ height: "500px", background: "#28282855", zIndex: 1 }}
          id="drop_zone"
        >
          <p style={{ zIndex: 0 }}>
            Drag one or more files to this{" "}
            <i>drop zone {isDragging.toString()}</i>.
          </p>
        </div>
      </PreviewWrapper>
    );
  }

  if (loading) {
    return (
      <PreviewWrapper h={height} w={width}>
        <Skeleton
          variant="rounded"
          width={width || "100%"}
          height={height || 200}
        />
      </PreviewWrapper>
    );
  }

  return (
    <PreviewContainerBox h={height} w={width}>
      {Array.isArray(fileList) &&
        fileList?.length > 0 &&
        fileList.map((f, idx) => {

          return (
            <Fragment key={`image_preview_${Math.random() + idx}`}>
              <MDBox sx={{ display: "flex", flexDirection: "row" }}>
                <FileContent key={`image_prev_${idx}`} h={height} w={width}>
                  <VideoSrc
                    h={height}
                    w={width}
                    src={f.preview}
                    role={"presentation"}
                    alt={"preview"}
                    style={imgStyle}
                  />
                  <HighlightOffIcon
                    fontSize="large"
                    className="remove-icon"
                    onClick={() => removeFile(f)}
                  />
                </FileContent>
              </MDBox>
            </Fragment>
          );
        })}
    </PreviewContainerBox>
  );
};

VideoPreviewContainer.propTypes = {
  fileList: PropTypes.array.isRequired,
  editList: PropTypes.func.isRequired,
  videoStyle: PropTypes.object,
  imgStyle: PropTypes.object,
  color: PropTypes.string,
};

VideoPreviewContainer.defaultProps = {
  fileList: [],
  editList: () => {},
  videoStyle: {},
  imgStyle: {},
  color: "#007CFF",
};

export default VideoPreviewContainer;
