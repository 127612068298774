
import * as yup from "yup";

export const validationSchema = yup.object({
  email: yup
  .string()
  .email("Lütfen geçerli bir email adresi girin.")
  .required("Email bilgisi zorunludur"),
  password: yup.string().required("Şifre bilgisi zorunludur"),
});

