import {createContext, useContext, useReducer} from "react";
import {authLogin} from "../../services/authService";
import {LanguageContext} from "../Language";


export const AuthContext = createContext();

const initialState = {
  user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null,
  token: localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")) : null,
  loading: false,
  language: "en",
  error: null,
};

const type = {
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILED: "LOGIN_FAILED",
  LOADING_TRUE: "LOADING_TRUE",
  LOGOUT: "LOGOUT",
};

const reducer = (state, action) => {
  switch (action.type) {
    case type.LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        lang: action.payload.lang || "en",
        loading: false,
      };
    case type.LOGIN_FAILED:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    case type.LOADING_TRUE:
      return {
        ...state,
        loading: true,
      };
    case type.LOGOUT:
      return {
        ...state,
        user: null,
        token: null,
        lang: null,
      };
    default:
      throw new Error();
  }
};

export const AuthProvider = ({children}) => {
  const [authState, dispatch] = useReducer(reducer, initialState);

  const {language} = useContext(LanguageContext);

  const login = async(payload) => {
    dispatch({
      type: "LOADING_TRUE",
    });
    const data = await authLogin({...payload, lang: language.code});
    if (data.error) {
      dispatch({
        type: type.LOGIN_FAILED,
        payload: {
          error: data.message,
        },
      });
    } else {
      localStorage.setItem("user", JSON.stringify(data.user));
      localStorage.setItem("token", JSON.stringify(data.access_token));
      localStorage.setItem("lang", JSON.stringify(language.code));
      dispatch({
        type: type.LOGIN_SUCCESS,
        payload: {
          lang: language.code,
          user: data.user,
          token: data.access_token,
        },
      });
      return true;
    }
  };

  const logout = () => {
    dispatch({
      type: type.LOGOUT,
    });
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("lang");
    window.location.href = "/authentication/sign-in";
    return true;
  };

  return (
    <AuthContext.Provider value={{authState, dispatch, login, logout}}>
      {children}
    </AuthContext.Provider>
  );
};
