import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter} from "react-router-dom";
import App from "./App";
import "./utils/stringFunction"

// Material Dashboard 2 React Context Provider
import {MaterialUIControllerProvider} from "./context";
import {AuthProvider} from "./context/auth";
import {LanguageProvider} from "./context/Language";

import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


ReactDOM.render(
  <LanguageProvider>
    <BrowserRouter>
      <MaterialUIControllerProvider>
        <AuthProvider>
          <App/>
          <ToastContainer position="bottom-right"/>
        </AuthProvider>
      </MaterialUIControllerProvider>
    </BrowserRouter>
  </LanguageProvider>,
document.getElementById("root")
)
;
