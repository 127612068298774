import {useContext, useState} from "react";
import {Spinner} from "react-bootstrap";

// react-router-dom components
import {Link, Navigate, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
// @mui material components
import Card from "@mui/material/Card";

import Switch from "@mui/material/Switch";

// Material Dashboard 2 React components
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";
import {AuthContext} from "../../../context/auth";
import CoverLayout from "../components/CoverLayout";
import Logo from "../../../assets/images/logo-ct.png";
import {useFormik} from "formik";
import {validationSchema} from "./validationSchema";

import Cover from "../../../assets/images/cover.svg";
import {LanguageContext} from "../../../context/Language";


function Basic(){

  let {login, authState} = useContext(AuthContext);

  const {t} = useContext(LanguageContext);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async(values) => {
      try {
        setLoading(true);
        const payload = {
          email: values.email,
          password: values.password,
        };
        const isLogged = await login(payload);
        if (isLogged) {
          navigate("/");
          toast.success(t("login.success"));
        } else {
          toast.error(authState.error.message);
          setLoading(false);
        }
      } catch(error) {
        console.log(error)
        toast.error(error.message);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    },
  });

  return authState.user ? (
    <Navigate to="/"/>
  ) : (
    <CoverLayout image={Cover}>
      <MDBox
        mb={7}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={Logo} width={169} height={51} alt="logo"/>
      </MDBox>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Admin Panel
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={formik.handleSubmit}>
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="Email"
                name="email"
                fullWidth
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label={t("login.password")}
                name="password"
                fullWidth
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
            </MDBox>
            <MDBox mt={2} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                disabled={loading}
                type="submit"
              >
                {loading ? (
                  <Spinner animation="border" role="status" size="sm">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                ) : (
                  t("login.button")
                )}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Basic;
