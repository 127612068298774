import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";


export const Date = ({ start, end }) => (
  <MDBox display="flex" alignItems="center" lineHeight={1}>
    <MDBox lineHeight={1}>
      <MDTypography variant="subtitle1" fontSize={12} fontWeight="light">
        {start}
      </MDTypography>
      <MDTypography fontSize={14} fontWeight="bold" color="info">
        {end}
      </MDTypography>
    </MDBox>
  </MDBox>
);
