import styled from "@emotion/styled";
import Icon from "@mui/material/Icon";

export const PreviewContainerBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: #f0f2f5;
  border: 1px dashed #14d0f1;
  cursor: pointer;
  overflow: hidden;
  width: 100%;
  height: ${props => `calc(${props.h || '220px'})`};
  position: relative;
  background-image: linear-gradient(45deg, #ccc 25%, transparent 25%),
  linear-gradient(-45deg, #ccc 25%, transparent 25%),
  linear-gradient(45deg, transparent 75%, #ccc 75%),
  linear-gradient(-45deg, transparent 75%, #ccc 75%);
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
`;
export const PreviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  width: ${props => `calc(${props.w || '100%'})`};
  height: ${props => `calc(${props.h || '220px'})`};
`;

export const FileContent = styled.div`
    // height: ${props => props.h || '220px'};
    // width: ${props => `calc(${props.w || '220px'})`};
  position: relative;
  display: grid;
  overflow: auto;

  .remove-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    color: red;
    font-size: 20px;
    cursor: pointer;
    display: none;
  }

  &:hover .remove-icon {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: red;
    font-size: 50px;
    cursor: pointer;
    display: none;
  }

  &:hover img {
    filter: blur(2px);
  }

  &:hover::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }

  &:hover .remove-icon {
    display: block;
    z-index: 2;
  }

`;

export const VideoSrc = styled.video`
  /* width: 40%; */
  width: 100%;
  height: 100%;
  height: ${props => props.h || 'auto'};
  object-fit: cover;
`;

export const ImageSrc = styled.img`
  height: ${props => props.h || 'auto'};
  width: ${props => props.w || '220px'};
  object-fit: cover;
  pointer-events: none;
  user-select: none;
`;

// export const VideoSrc = styled.video`
//   height: ${props => props.h || 'auto'};
//   width: ${props => props.w || '220px'};
//   object-fit: cover;
//   pointer-events: none;
//   user-select: none;
// `;

export const FileInputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
`;

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  justify-self: flex-end;
  padding: 10px 0;
  overflow: hidden;
`;

export const CloseIcon = styled(Icon)`
  margin-left: 2px;
  cursor: pointer;
  &:hover {
    color: #c70000;
  }
`;

export const StatusText = styled.p`
  font-size: 15px;
  overflow: hidden;
  line-break: normal;
  margin-right: 10px;
  color: ${({ $color }) => $color};
`;
