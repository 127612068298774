import {appInstance} from './http';
import errorHandler from "./errorHandler";
import {buildQuery} from "./buildQuery";

export const getFoods = async({limit, page, lang, is_crud, pagination = 1}) => {
  const query = buildQuery({limit, page, is_crud, pagination});
  const queryParams = new URLSearchParams(query).toString();
  try {
    const {data} = await appInstance({lang}).get(`/food?${queryParams}`);
    return data;
  } catch(error) {
    throw errorHandler(error);
  }
}

export const getFood = async({id, lang}) => {
  try {
    const {data} = await appInstance({lang}).get(`/food/${id}`);
    return data;
  } catch(error) {
    throw errorHandler(error);
  }
}

export const createFood = async({name, food_category, lang}) => {
  const payload = {
    name,
    food_category,
  }
  try {
    const {data} = await appInstance({lang}).post(`/food`, payload);
    return data;
  } catch(error) {
    throw errorHandler(error);
  }
}

export const updateFood = async({id, name, food_category, lang}) => {
  const payload = {
    name,
    food_category,
  }
  try {
    const {data} = await appInstance({lang}).put(`/food/${id}`, payload);
    return data;
  } catch(error) {
    throw errorHandler(error);
  }
}

export const removeFood = async({id, lang}) => {
  try {
    const {data} = await appInstance({lang}).delete(`/food/${id}`);
    return data;
  } catch(error) {
    throw errorHandler(error);
  }
}
