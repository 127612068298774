import React from "react";
import MUIModal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import {Stack} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import MDTypography from "../MDTypography";
import MDButton from "../MDButton";
import styled from "styled-components";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#fff",
  p: 4,
  outline: "none",
  borderRadius: "15px",
  // overflow: 'hidden'
};

const Modal = ({title, onClose, beforeOpen, children, customStyle, closed}, ref) => {
  const [isVisible, setVisible] = React.useState(false);

  // ref sometimes doesn't work. because if we open modal from another component, ref is not defined yet. So we need to use
  // useEffect this useEffect will be called when ref is defined This is a workaround. I don't know why ref doesn't work
  React.useEffect(() => {
    if (closed) {
      openModal();
    } else {
      closeModal();
    }
  }, [closed]);

  React.useImperativeHandle(ref, () => ({
    open: () => {
      openModal();
    },
    close: () => {
      closeModal();
    },
  }));

  const openModal = async() => {
    setVisible(true);
    await beforeOpen?.();
  };

  const closeModal = async() => {
    setVisible(false);
    await onClose?.();
  };

  return (
    <MUIModal ref={ref} open={isVisible} onClose={closeModal}>
      <Box sx={{...style, ...customStyle}}>
        {/*<Stack flexDirection="row" mb={1}>*/}
        {/*    <CloseIcon onClick={closeModal} sx={{marginLeft: "auto"}}/>*/}
        {/*</Stack>*/}
        {title && <MDTypography mb={3} textTransform="capitalize" color="info" variant="h5">{title}</MDTypography>}
        {children}
      </Box>
    </MUIModal>
  );
};

export default React.forwardRef(Modal);
