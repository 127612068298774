import React, {useState, createContext} from 'react';
import trData from "../assets/languages/tr.json"
import enData from "../assets/languages/en.json"


export const LanguageContext = createContext();

const LANGUAGES = Object.freeze([
  {
    code: 'en',
    data: enData
  },
  {
    code: 'tr',
    data: trData
  }
]);

export const LanguageProvider = ({children}) => {
  const langCode = localStorage.getItem('lang')
  const [language, setLanguage] = useState({code: langCode || "en", data: trData});
  const langChannel = new BroadcastChannel('language');

  React.useEffect(() => {
    initialize();
    langChannel.onmessage = ({data}) => {
      if (data.type === 'change_language') {
        const selected = LANGUAGES.filter(e => e.code === data.payload.code)[0];
        setLanguage(selected);
        document.documentElement.setAttribute('lang', data.payload.code);
      }
    };
    // eslint-disable-next-line
  }, []);

  const changeLanguage = (code) => {
    const selected = LANGUAGES.filter(e => e.code === code)[0];
    if (!!selected) {
      setLanguage(selected);
      document.documentElement.setAttribute('lang', code);
      window.localStorage.setItem('lang', code);
      langChannel.postMessage({type: 'change_language', payload: {code}});
    } else {
      window.localStorage.removeItem('lang');
    }
  };

  const initialize = () => {
    const selectedLang = window.localStorage.getItem('lang');
    if (!!selectedLang) {
      changeLanguage(selectedLang);
    } else {
      const userLang = navigator.language || navigator.userLanguage;
      changeLanguage(userLang.split('-')[0]);
    }
  };
  const deepObject = (obj, key = []) => {
    if (typeof obj === 'object') {
      if (key.length === 1) {
        return obj[key[0]];
      } else {
        return deepObject(obj[key.shift()], key);
      }
    }
    return obj;
  };

  function replacePlaceholder(text, placeholder, replacement){
    return text.replace(new RegExp(placeholder, 'g'), replacement);
  };

  const t = (key = '', format) => {
    let resultText = deepObject(language.data, key.split('.'));
    if (typeof resultText === 'object' || resultText === undefined) {
      // console.log(`Translation error on key: ${key}`);
      return key;
    }
    if (format && typeof format === 'object') {
      const formatKeys = Object.keys(format);
      for (const tx of formatKeys) {
        resultText = replacePlaceholder(resultText, `{{${tx}}}`, format[tx]);
      }
    }
    return resultText;
  };

  const getAvailableLanguages = () => {
    return LANGUAGES.map(lang => { return {code: lang.code} });
  };

  return <LanguageContext.Provider
    value={{
      language,
      changeLanguage,
      t,
      getAvailableLanguages,
    }}
  >
    {children}
  </LanguageContext.Provider>;
};
