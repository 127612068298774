import DashboardLayout from "../../components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../components/Navbars/DashboardNavbar";
import {useFetchData} from "../../hooks/useFetchData";
import LoadingComponent from "../../components/LoadingComponent";
import React, {useContext, useEffect, useRef, useState} from "react";
import MDBox from "../../components/MDBox";
import Modal from "../../components/Modal";
import {UpdateRequestForm} from "./update";
import {ConfirmModal} from "../../components/Modal/confirm/confirm";
import {toast} from "react-toastify";
import {cancelRequestForm, getRequestForm, removeRequestForm} from "../../services/requestFormService";
import {columns} from "./components/columns";
import NewTable from "../../components/Tables1/Table";
import {LanguageContext} from "../../context/Language";
import {removeGallery} from "../../services/galleryService";
import {AddAssets} from "../gallery/add";
import {CreateRequestForm} from "./add";
import {useNavigate} from "react-router-dom";
import {getOrderStates} from "../../services/orderStateService";
import {getRequestFormStates} from "../../services/requestFormStateService";


export const RequestForm = () => {
  const [selectedRequestForm, setSelectedRequestForm] = useState([]);
  const [modal, setModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [selectedRequestFormId, setSelectedRequestFormId] = useState(null);
  const addRequestFormRef = useRef(null);
  const updateRequestFormRef = useRef(null);
  const deleteRequestFormRef = useRef(null);

  const navigate = useNavigate()

  const {t, language} = useContext(LanguageContext);

  const {
    loading,
    data,
    setData,
    limit,
    setLimit,
    filter,
    setFilter,
    setPage,
    page,
    maxPage,
    fetchData,
  } = useFetchData(getRequestForm, [], language.code);

  const navigateToDetail = (id) => {
    navigate(`/request-form/create-booking/${id}`)
  }


  const addAssetModal = () => {
    addRequestFormRef.current.open();
    setModal(true);
  };
  const handleConfirm = (id) => {
    deleteRequestFormRef.current.open();
    setSelectedRequestFormId(id);
  };
  const updateRequestForm = (currentRequestForm) => {
    setSelectedRequestForm(currentRequestForm);
    updateRequestFormRef.current.open();
    setUpdateModal(true);
  };

  const cancelRequestForm = async() => {
    try {
      await removeRequestForm({id: selectedRequestFormId});
      fetchData();
    } catch(error) {
      console.log(error)
      toast.error(error);
    }
  };



  if (loading) return <LoadingComponent/>;

  return (
    <DashboardLayout>
      <DashboardNavbar/>
      <Modal
        ref={updateRequestFormRef}
        closed={updateModal}
        children={
          <UpdateRequestForm
            fetchData={() => fetchData()}
            selectedRequestForm={selectedRequestForm}
            closeModal={() => setUpdateModal((prev) => !prev)}
          />
        }
        customStyle={{
          width: {xs: "90%", md: "40%"},
          maxHeight: "90vh",
          overflow: "inherit",
        }}
      />
      <Modal
        ref={addRequestFormRef}
        closed={modal}
        children={<CreateRequestForm fetchData={() => fetchData()} closeModal={() => setModal((prev) => !prev)}/>}
        customStyle={{
          width: {xs: "90%", md: "40%"},
          maxHeight: "90vh",
          overflow: "auto",
        }}
      />
      <MDBox p={2}>
        <NewTable
          title={t("requestForm.title")}
          limit={limit}
          setLimit={setLimit}
          setPage={setPage}
          page={page}
          maxPage={maxPage}
          prevFunc={() => setPage(page - 1)}
          nextFunc={() => setPage(page + 1)}
          columns={columns({handleConfirm, updateRequestForm, navigateToDetail, t})}
          canSearch={false}
          rows={data}
          isFilter={false}
          filter={filter}
          setFilter={setFilter}
          options={[{label: t("filter.wait"), value: 0}, {label: t("filter.canceled"), value: 1}]}
          // addFunc={addAssetModal}
        />
      </MDBox>
    </DashboardLayout>
  )
}
