/**
 All of the routes for the Material Dashboard 2 React are added here,
 You can add a new route, customize the routes and delete the routes here.

 Once you add a new route on this file it will be visible automatically on
 the Sidenav.

 For adding a new route you can follow the existing routes in the routes array.
 1. The `type` key with the `collapse` value is used for a route.
 2. The `type` key with the `title` value is used for a title inside the Sidenav.
 3. The `type` key with the `divider` value is used for a divider between Sidenav items.
 4. The `name` key is used for the name of the route on the Sidenav.
 5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
 6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
 7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
 inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
 8. The `route` key is used to store the route location which is used for the react router.
 9. The `href` key is used to store the external links location.
 10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
 10. The `component` key is used to store the component of its route.
 */

// Material Dashboard 2 React layouts
import SignIn from "./pages/authentication/sign-in";
import SignUp from "./pages/authentication/sign-up";
import Cover from "./pages/authentication/reset-password/cover";
import Dashboard from "./pages/dashboard";
// import Billing from "./layouts/billing";
// import Notifications from "./layouts/notifications";
// import Profile from "./layouts/profile";

// @mui icons
import Icon from "@mui/material/Icon";
import NotFound from "./pages/NotFound";
import {Foods} from "./pages/foods";
// import {TableStructure} from "./pages/tableStructure";
// import {Products} from "./pages/product";
// import {CreateProduct} from "./pages/product/create";
import {Gallery} from "./pages/gallery";
import {Booking} from "./pages/booking";
import {Orders} from "./pages/order";
import {RequestForm} from "./pages/request-form";
import {CreateBooking} from "./pages/createBooking";
import {ContactForm} from "./pages/contact-form";

const routes = ({t}) => {
  return [
    {
      name: 'hc',
      key: 'hc',
      route: '/hc',
      component: () => <div>ok</div>,
      protected: false,
    },
    {
      type: "collapse",
      name: t("route.order"),
      key: "",
      icon: <Icon fontSize="small">receipt</Icon>,
      route: "/",
      component: <Orders/>,
      protected: true,
    },
    {
      type: "collapse",
      name: t("route.requestForm"),
      key: "request-form",
      icon: <Icon fontSize="small">contact_mail</Icon>,
      route: "/request-form",
      component: <RequestForm/>,
      protected: true,
    },
    {
      type: "collapse",
      name: t("route.contactForm"),
      key: "contact-form",
      icon: <Icon fontSize="small">contact_page</Icon>,
      route: "/contact-form",
      component: <ContactForm/>,
      protected: true,
    },
    {
      type: "collapse",
      name: "Sign In",
      key: "sign-in",
      icon: <Icon fontSize="small">login</Icon>,
      route: "/authentication/sign-in",
      component: <SignIn/>,
      protected: false,
      hidden: true,
    },
    {
      type: "collapse",
      name: "Reset Password",
      key: "reset-password",
      icon: <Icon fontSize="small">login</Icon>,
      route: "/authentication/reset-password",
      component: <Cover/>,
      protected: false,
      hidden: true,
    },
    {
      type: "collapse",
      name: "Sign Up",
      key: "sign-up",
      icon: <Icon fontSize="small">assignment</Icon>,
      route: "/authentication/sign-up",
      component: <SignUp/>,
      protected: false,
      hidden: true,
    },
    {
      type: "collapse",
      name: "Not Found",
      key: "not-found",
      icon: <Icon fontSize="small">assignment</Icon>,
      route: "/notfound",
      component: <NotFound/>,
      protected: false,
      hidden: true,
    },
    {
      type: "collapse",
      name: t("route.booking"),
      key: "booking",
      icon: <Icon fontSize="small">calendar_month</Icon>,
      route: "/booking",
      component: <Booking/>,
      protected: true,
    },
    {
      type: "collapse",
      name: t("route.food"),
      key: "foods",
      icon: <Icon fontSize="small">fastfood</Icon>,
      route: "/foods",
      component: <Foods/>,
      protected: true,
    },
    {
      type: "collapse",
      name: t("route.gallery"),
      key: "gallery",
      icon: <Icon fontSize="small">filter</Icon>,
      route: "/gallery",
      component: <Gallery/>,
      protected: true,
    },
    {
      type: "collapse",
      name: "Create Booking",
      key: "create-booking",
      icon: <Icon fontSize="small">contact_mail</Icon>,
      route: "/request-form/create-booking/:id",
      component: <CreateBooking/>,
      protected: true,
      hidden: true,
    },
  ];
};

export default routes;
