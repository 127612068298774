import React, {Fragment, useContext, useEffect, useState} from "react";

// 3rd libraries
import Select from "react-select";
import {useFormik} from "formik";
import {toast} from "react-toastify";
import dayjs from "dayjs";

// @mui
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker';
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

//components
import MDInput from "../../../components/MDInput";
import MDTypography from "../../../components/MDTypography";
import MDButton from "../../../components/MDButton";
import {styles} from "../components/styles";
import Uploader from "../../../components/Uploader";
import {FoodSelect} from "../components/foodSelect";
import MDBox from "../../../components/MDBox";

// services
import {createBooking} from "../../../services/bookingService";
import {getFoodCategoriesDd} from "../../../services/foodCategoryService";

// context
import {LanguageContext} from "../../../context/Language";

//utils
import {convertToFullDate} from "../../../utils/timeConvert";


const bookingType = ['Dinner', 'Lunch', 'Cocktail']
export const CreateBooking = ({closeModal, fetchData}) => {
  const [loading, setLoading] = useState(false);
  const [foodCategory, setFoodCategory] = useState([]);

  const [image, setImage] = useState(null);
  const [fileList, setFiles] = useState([]);

  const {t, language} = useContext(LanguageContext);

  useEffect(() => {
    fetchFoodCategory();
  }, []);

  console.log(foodCategory)

  const fetchFoodCategory = async() => {
    try {
      const data = await getFoodCategoriesDd({lang: language.code});
      setFoodCategory(data);
    } catch(error) {
      console.log(error);
    }
  }

  const formik = useFormik({
    initialValues: {
      name: {tr: "", en: ""},
      start_date: dayjs(),
      end_date: dayjs(),
      starterFood: [],
      mainFood: [],
      dessertFood: [],
      bookingType: [],
      saladFood: [],
      cocktailFood: [],
      price: 0,
      total_stock: 0,
      online_stock: 0,
    },
    onSubmit: async(values) => {
      if (!image) return toast.error(t("booking.create.imageError"));
      const payload = {
        image: image,
        name: {tr: values.name.tr, en: values.name.en},
        start_date: convertToFullDate(values.start_date),
        end_date: convertToFullDate(values.end_date),
        booking_type: values.bookingType.value.toLowerCase(),
        price: values.price,
        total_stock: values.total_stock,
        online_stock: values.online_stock
      };
      if (values.bookingType.value === "cocktail") payload.cocktail_food = values.cocktailFood.map(item => item.value);
      if (values.bookingType.value !== "cocktail") {
        payload.starter_food = values.starterFood.map(item => item.value);
        payload.main_food = values.mainFood.map(item => item.value);
        payload.dessert_food = values.dessertFood.map(item => item.value);
        payload.salad_food = values.saladFood.map(item => item.value);
      }
      try {
        await createBooking(payload);
        toast.success(t("booking.create.success"));
        closeModal?.();
        fetchData?.()
      } catch(error) {
        console.log(error)
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid xs={12}><MDTypography ml={2} color="info" fontWeight="bold">{t("booking.create.title")}</MDTypography></Grid>
          <Grid item xs={12} md={8}>
            <Uploader
              fileList={fileList}
              onFileChange={(e) => setFiles(e)}
              text="Upload Booking Images"
              setImage={setImage}
              loading={loading}
              setLoading={setLoading}
              width="100%"
              height="290px"
            />
          </Grid>
          <Grid item container spacing={2} xs={12} md={4}>
            <Grid item xs={6} md={12} sx={{marginLeft: 2}}>
              <DateTimePicker
                label="Start Date"
                value={formik.values.start_date}
                onChange={(value) =>
                  formik.setFieldValue("start_date", value)
                }
                ampm={false}
                format="dd/MM/yyyy HH:mm"
                renderInput={(params) => <TextField {...params} />}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.start_date &&
                  Boolean(formik.errors.start_date)
                }
              />
            </Grid>
            <Grid item xs={6} md={12}>
              <MDBox sx={{marginLeft: 2}}>
                <DateTimePicker
                  label="End Date"
                  ampm={false}
                  format="dd/MM/yyyy HH:mm"
                  value={formik.values.end_date}
                  onChange={(value) => formik.setFieldValue("end_date", value)}
                  renderInput={(params) => <TextField {...params} />}
                  onBlur={formik.handleBlur}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={12}>
              <MDInput
                fullWidth
                label={t("booking.inputs.price")}
                name="price"
                type="number"
                value={formik.values.price}
                onChange={formik.handleChange}
                error={formik.touched.price && Boolean(formik.errors.price)}
                helperText={formik.touched.price && formik.errors.price}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <MDInput
                fullWidth
                type="number"
                label={t("booking.inputs.total_stock")}
                name="total_stock"
                value={formik.values.total_stock}
                onChange={formik.handleChange}
                error={formik.touched.total_stock && Boolean(formik.errors.total_stock)}
                helperText={formik.touched.total_stock && formik.errors.total_stock}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <MDInput
                fullWidth
                type="number"
                label={t("booking.inputs.online_stock")}
                name="online_stock"
                value={formik.values.online_stock}
                onChange={formik.handleChange}
                error={formik.touched.online_stock && Boolean(formik.errors.online_stock)}
                helperText={formik.touched.online_stock && formik.errors.online_stock}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <MDInput
              fullWidth
              label={t("booking.inputs.name.tr")}
              name="name.tr"
              value={formik.values.name.tr}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <MDInput
              fullWidth
              label={t("booking.inputs.name.en")}
              name="name.en"
              value={formik.values.name.en}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Select
              fullWidth
              placeholder={t("booking.select.type")}
              styles={styles}
              isClearable={true}
              noOptionsMessage={({inputValue}) =>
                `${inputValue || t("foods.inputs.food")} ${t("foods.inputs.notfound")}.`
              }
              value={formik.values.bookingType}
              onChange={(value) => formik.setFieldValue("bookingType", value)}
              options={bookingType?.map((item) => {
                return {
                  label: item,
                  value: item.toLowerCase(),
                };
              })}
            />
          </Grid>
          {formik.values.bookingType?.value === "cocktail" && (
            <Grid item xs={12} md={12}>
              <FoodSelect
                value={formik.values.cocktailFood}
                onChange={(value) => formik.setFieldValue("cocktailFood", value)}
                placeholder={t("booking.select.coctail")}
                inputVal={t("booking.inputs.coctail")}
                options={foodCategory?.cocktail?.foods}
              />
            </Grid>
          )}
          {formik.values.bookingType?.value && formik.values.bookingType?.value !== "cocktail" && (
            <Fragment>
              <Grid item xs={12} md={12}>
                <FoodSelect
                  value={formik.values.saladFood}
                  onChange={(value) => formik.setFieldValue("saladFood", value)}
                  placeholder={t("booking.select.salad")}
                  inputVal={t("booking.inputs.salad")}
                  options={foodCategory?.salad?.foods}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <FoodSelect
                  value={formik.values.starterFood}
                  onChange={(value) => formik.setFieldValue("starterFood", value)}
                  placeholder={t("booking.select.starter")}
                  inputVal={t("booking.inputs.starter")}
                  options={foodCategory["starter-starch"]?.foods}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <FoodSelect
                  value={formik.values.mainFood}
                  onChange={(value) => formik.setFieldValue("mainFood", value)}
                  placeholder={t("booking.select.main-food")}
                  inputVal={t("booking.inputs.main-food")}
                  options={foodCategory["main-dishes"]?.foods}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <FoodSelect
                  value={formik.values.dessertFood}
                  onChange={(value) => formik.setFieldValue("dessertFood", value)}
                  placeholder={t("booking.select.dessert")}
                  inputVal={t("booking.inputs.dessert")}
                  options={foodCategory?.dessert?.foods}
                />
              </Grid>
            </Fragment>
          )}
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={7} md={8}>
              <MDButton
                disabled={loading}
                variant="contained"
                color="info"
                fullWidth
                type="submit"
              >
                {t("buttons.save")}
              </MDButton>
            </Grid>
            <Grid item xs={5} md={4}>
              <MDButton
                disabled={loading}
                variant="outlined"
                color="error"
                fullWidth
                onClick={closeModal}
              >
                {t("buttons.cancel")}
              </MDButton>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </LocalizationProvider>
  )
}
