import {Tooltip} from "@mui/material";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import UnpublishedOutlinedIcon from "@mui/icons-material/UnpublishedOutlined";
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import {Contacts, Pending} from "@mui/icons-material";
import {useContext} from "react";
import {LanguageContext} from "../../../context/Language";


export const Items = ({name, value}) => (
  <MDBox
    sx={{
      mb: 2,
      width: "100%",
      display: "flex",
      alignItems: "center",
    }}
  >
    <MDTypography
      mr={1}
      variant="caption"
      color="dark"
      fontWeight="medium"
      fontSize={15}
    >
      {name}
    </MDTypography>
    <MDTypography
      ml={"auto"}
      color="inherit"
      variant="caption"
      fontSize={15}
    >
      {value}
    </MDTypography>
  </MDBox>
);

export const User = ({name, surname, email}) => (
  <MDBox display="flex" alignItems="center" lineHeight={1}>
    <MDBox lineHeight={1}>
      <MDTypography display="block" variant="button" fontWeight="medium">
        {`${name} ${surname}`}
      </MDTypography>
      <MDTypography variant="caption">{email}</MDTypography>
    </MDBox>
  </MDBox>
);

export const InstitutionAndCity = ({institution, city}) => (
  <MDBox lineHeight={1} textAlign="left">
    <MDTypography
      display="block"
      variant="caption"
      color="text"
      fontWeight="medium"
    >
      {institution ?? "No Institution"}
    </MDTypography>
    <MDTypography variant="caption">{city ?? "No City"}</MDTypography>
  </MDBox>
);

export const Status = ({status}) => {
  const {t} = useContext(LanguageContext)
  let color, icon, title;

  switch (status) {
    case 'new-request':
      title = t('status.new_request');
      color = 'info';
      icon = <Pending fontSize="medium"/>;
      break;
    case 'customer-contacted':
      title = t('status.customer_contacted');
      color = 'warning';
      icon = <Contacts fontSize="medium"/>;
      break;
    case 'order-fulfilled':
      title = t('status.order_fulfilled');
      color = 'success';
      icon = <CheckCircleOutlineOutlinedIcon fontSize="medium"/>;
      break;
    case 'customer-gave-up':
      title = t('status.customer_gave_up');
      color = 'error';
      icon = <CancelOutlinedIcon fontSize="medium"/>;
      break;
    default:
      color = 'success';
      icon = <CheckCircleOutlineOutlinedIcon fontSize="medium"/>;
      break;
  }

  return (
    <Tooltip title={title}>
      <MDBox color={color}>
        {icon}
      </MDBox>
    </Tooltip>
  );
};

