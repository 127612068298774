import {useNavigate} from "react-router-dom";

import MDTypography from "../../../components/MDTypography";
import Tooltip from "@mui/material/Tooltip";
import MDButton from "../../../components/MDButton";
import {convertToDate, convertToTimeUTC, convertToUtcWithoutTime} from "../../../utils/timeConvert";
import {Stack} from "@mui/material";
import {useContext} from "react";
import {LanguageContext} from "../../../context/Language";
import {Status, User} from "./items";
import {ArrowForward, Block} from "@mui/icons-material";
import {Date} from "../../order/components/Date";
import EditIcon from "@mui/icons-material/Edit";


export const columns = ({handleConfirm, updateRequestForm, navigateToDetail, t}) => {
  return [
    {
      Header: t("requestForm.tableHeader.user"),
      accessor: "name",
      align: "left",
      width: "100%",
      Cell: ({row}) => (
        <User name={row.original.name} email={row.original.email} surname={row.original.surname}/>
      ),
    },
    {
      Header: t("requestForm.tableHeader.unit"),
      accessor: "unit",
      align: "left",
      Cell: ({row}) => (
        <MDTypography variant="title1" fontWeight="medium">
          {row.original.unit}
        </MDTypography>
      ),
    },
    {
      Header: t("requestForm.tableHeader.customer_type"),
      accessor: "customer_type",
      align: "left",
      Cell: ({row}) => (
        <MDTypography variant="title1" fontWeight="medium">
          {row.original.customer_type}
        </MDTypography>
      ),
    },
    {
      Header: t("requestForm.tableHeader.phone"),
      accessor: "phone",
      align: "left",
      Cell: ({row}) => (
        <MDTypography variant="title1" fontWeight="medium">
          {row.original.phone}
        </MDTypography>
      ),
    },
    {
      Header: t("requestForm.tableHeader.company_name"),
      accessor: "company_name",
      align: "left",
      Cell: ({row}) => (
        <MDTypography variant="title1" fontWeight="medium">
          {row.original.company_name}
        </MDTypography>
      ),
    },
    {
      Header: t("requestForm.tableHeader.createdAt"),
      accessor: "createdAt",
      align: "left",
      Cell: ({row}) => (
        <Date
          start={`${convertToUtcWithoutTime(row.original.created_at)}`}
          end={`${convertToTimeUTC(row.original.created_at)}`}
        />
      ),
    },
    {
      Header: t("requestForm.tableHeader.status"),
      accessor: "cancel",
      align: "left",
      Cell: ({row}) => (
        <Status status={row.original.reservation_request_form_state.name_slug}/>
      ),
    },
    {
      Header: t("requestForm.tableHeader.actions"),
      accessor: "actions",
      align: "left",
      isSticky: true,
      Cell: ({row}) => (
        <>
          {!row.original.cancel && (
            <>
              <Tooltip title={t("buttons.detail")}>
                <MDButton
                  size="small"
                  sx={{maxWidth: 30, minWidth: 30, width: 30}}
                  color="warning"
                  onClick={() => updateRequestForm(row.original)}
                >
                  <EditIcon/>
                </MDButton>
              </Tooltip>
            </>
          )}
        </>
      ),
    },
  ];
};
