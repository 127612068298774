import {useState, useEffect, useContext, useRef} from 'react';
import {toast} from "react-toastify";
import {LanguageContext} from "../context/Language";


export const useFetchData = (getData, initialData, lang, is_crud, filtered, setFiltered) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(initialData);
  const [maxPage, setMaxPage] = useState(0);
  const [filter, setFilter] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [error, setError] = useState(null);
  const [searchLoading, setSearchLoading] = useState(false);

  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    if (search && search.length > 0 && search.length <= 2) return;
    fetchData();
    return () => {
      isMounted.current = false;
    };
  }, [page, limit, search, filter, filtered]);

  const fetchData = async() => {
    try {
      setLoading(!search);
      setSearchLoading(search && search.length >= 1);
      setData([]);
      const result = await getData({page, limit, lang, is_crud, filter, filtered, search,});
      if (isMounted.current) { // Sadece bileşen mount durumdaysa state'i güncelle
        setData(result.data);
        setMaxPage(result.pagination_info.total_page);
      }
    } catch(err) {
      if (isMounted.current) { // Sadece bileşen mount durumdaysa state'i güncelle
        setError(err);
        setData([]);
      }
    } finally {
      if (isMounted.current) { // Sadece bileşen mount durumdaysa state'i güncelle
        setError(null);
        setLoading(false);
        setSearchLoading(false);
      }
    }
  };

  return {
    loading,
    setData,
    data,
    maxPage,
    setMaxPage,
    page,
    setPage,
    limit,
    setLimit,
    search,
    setSearch,
    error,
    setError,
    searchLoading,
    filter,
    setFilter,
    fetchData,
  };
};
