import DashboardLayout from "../../components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../components/Navbars/DashboardNavbar";
import {useFetchData} from "../../hooks/useFetchData";
import LoadingComponent from "../../components/LoadingComponent";
import React, {useContext, useRef, useState} from "react";
import MDBox from "../../components/MDBox";
import Modal from "../../components/Modal";
import { UpdateContactForm} from "./update";
import {ConfirmModal} from "../../components/Modal/confirm/confirm";
import {toast} from "react-toastify";
import { getContactForm, removeContactForm} from "../../services/contactFormService";
import {columns} from "./components/columns";
import NewTable from "../../components/Tables1/Table";
import {LanguageContext} from "../../context/Language";


export const ContactForm = () => {
  const [selectedContactForm, setSelectedContactForm] = useState([]);
  const [modal, setModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [selectedContactFormId, setSelectedContactFormId] = useState(null);
  const updateContactFormRef = useRef(null);
  const deleteContactFormRef = useRef(null);

  const {t, language} = useContext(LanguageContext);

  const {
    loading,
    data,
    setData,
    limit,
    setLimit,
    filter,
    setFilter,
    setPage,
    page,
    maxPage,
    fetchData,
  } = useFetchData(getContactForm, [], language.code);



  const handleConfirm = (id) => {
    deleteContactFormRef.current.open();
    setSelectedContactFormId(id);
  };
  const updateContactForm = (currentContactForm) => {
    setSelectedContactForm(currentContactForm);
    updateContactFormRef.current.open();
    setUpdateModal(true);
  };

  const cancelContactForm = async() => {
    try {
      await removeContactForm({id: selectedContactFormId});
      fetchData();
    } catch(error) {
      console.log(error)
      toast.error(error.message);
    }
  };
  if (loading) return <LoadingComponent/>;

  return (
    <DashboardLayout>
      <DashboardNavbar/>
      <Modal
        ref={updateContactFormRef}
        closed={updateModal}
        children={
          <UpdateContactForm
            fetchData={() => fetchData()}
            selectedContactForm={selectedContactForm}
            closeModal={() => setUpdateModal((prev) => !prev)}
          />
        }
        customStyle={{
          width: {xs: "90%", md: "40%"},
          maxHeight: "90vh",
          overflow: "inherit",
        }}
      />
      <Modal
        ref={deleteContactFormRef}
        children={
          <ConfirmModal
            closeModal={deleteContactFormRef.current?.close}
            deleteFunc={() => cancelContactForm()}
            title={t("contactForm.deleteMessage")}
          />
        }
      />
      <MDBox p={2}>
        <NewTable
          title={t("contactForm.tableHeader.title")}
          limit={limit}
          setLimit={setLimit}
          setPage={setPage}
          page={page}
          maxPage={maxPage}
          prevFunc={() => setPage(page - 1)}
          nextFunc={() => setPage(page + 1)}
          columns={columns({handleConfirm, updateContactForm, t})}
          canSearch={false}
          rows={data}
          isFilter={true}
          filter={filter}
          setFilter={setFilter}
          options={[{label: t("filter.wait"), value: 0}, {label: t("filter.is_read"), value: 1}]}
          // addFunc={addAssetModal}
        />
      </MDBox>
    </DashboardLayout>
  )
}
