import React from "react";
import AppLogo from "../../assets/images/logo.svg";
import { AnimatedLogo, Backdrop } from "./style";

const LoadingComponent = () => {
  return (
    <Backdrop>
      <AnimatedLogo src={AppLogo} alt="logo" />
    </Backdrop>
  );
};

export default LoadingComponent;
