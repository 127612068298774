import React, {useContext, useEffect, useState} from "react";

// 3rd libraries
import Select from "react-select";
import {useFormik} from "formik";
import {toast} from "react-toastify";

// @mui

import Grid from "@mui/material/Grid";

//components
import MDInput from "../../../components/MDInput";
import MDTypography from "../../../components/MDTypography";
import MDButton from "../../../components/MDButton";
import {styles} from "../components/styles";

// services
import {createFood, updateFood} from "../../../services/foodService";
import {getFoodCategories} from "../../../services/foodCategoryService";

// context
import {LanguageContext} from "../../../context/Language";


export const UpdateFood = ({selectedFood, closeModal, fetchData}) => {
  const [loading, setLoading] = useState(false);
  const [foodCategory, setFoodCategory] = useState([]);

  const {t, language} = useContext(LanguageContext);

  useEffect(() => {
    fetchFoodCategory();
  }, []);

  const fetchFoodCategory = async() => {
    try {
      const data = await getFoodCategories({lang: language.code});
      setFoodCategory(data);
    } catch(error) {
      console.log(error);
    }
  }

  const formik = useFormik({
    initialValues: {
      name: {tr: selectedFood.name.tr, en: selectedFood.name.en},
      foodCategory: {label: selectedFood.food_category.name[language.code], value: selectedFood.food_category._id},
    },
    onSubmit: async(values) => {
      const payload = {
        id: selectedFood.id,
        name: {tr: values.name.tr, en: values.name.en},
        food_category: values.foodCategory.value
      };
      try {
        await updateFood(payload);
        toast.success(t("foods.update.success"));
        closeModal?.();
        fetchData?.()
      } catch(error) {
        console.log(error)
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid xs={12}><MDTypography ml={2} color="info" fontWeight="bold">{t("foods.update.title")}</MDTypography></Grid>
        <Grid item xs={12} md={12}>
          <MDInput
            fullWidth
            label={t("foods.inputs.name.tr")}
            name="name.tr"
            value={formik.values.name.tr}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <MDInput
            fullWidth
            label={t("foods.inputs.name.en")}
            name="name.en"
            value={formik.values.name.en}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Select
            fullWidth
            placeholder={t("foods.inputs.foodCategory")}
            styles={styles}
            isClearable={true}
            noOptionsMessage={({inputValue}) =>
              `${inputValue || t("foods.inputs.food")} ${t("foods.inputs.notfound")}.`
            }
            value={formik.values.foodCategory}
            onChange={(value) => formik.setFieldValue("foodCategory", value)}
            options={foodCategory?.map((item) => {
              return {
                label: item.name,
                value: item.id,
              };
            })}
          />
        </Grid>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={7} md={8}>
            <MDButton
              disabled={loading}
              variant="contained"
              color="info"
              fullWidth
              type="submit"
            >
              {t("buttons.save")}
            </MDButton>
          </Grid>
          <Grid item xs={5} md={4}>
            <MDButton
              disabled={loading}
              variant="outlined"
              color="error"
              fullWidth
              onClick={closeModal}
            >
              {t("buttons.cancel")}
            </MDButton>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}
