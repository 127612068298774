import {appInstance} from './http';
import errorHandler from "./errorHandler";
import {buildQuery} from "./buildQuery";


export const getsGallery = async({limit, page, lang, is_crud, pagination = 1}) => {
  console.log("lang", lang)
  const query = buildQuery({limit, page, is_crud, pagination});
  const queryParams = new URLSearchParams(query).toString();
  try {
    const {data} = await appInstance({lang}).get(`/gallery?${queryParams}`);
    return data;
  } catch(error) {
    throw errorHandler(error);
  }
}

export const getGallery = async({id, lang}) => {
  try {
    const {data} = await appInstance({lang}).get(`/gallery/${id}`);
    return data;
  } catch(error) {
    throw errorHandler(error);
  }
}

export const createGallery = async({file, file_type, lang}) => {
  const payload = {
    file,
    file_type,
  }
  try {
    const {data} = await appInstance({lang}).post(`/gallery`, payload);
    return data;
  } catch(error) {
    throw errorHandler(error);
  }
}


export const updateGallery = async({id, file, file_type, lang}) => {
  const payload = {
    file,
    file_type,
  }
  try {
    const {data} = await appInstance({lang}).put(`/gallery/${id}`, payload);
    return data;
  } catch(error) {
    throw errorHandler(error);
  }
}

export const removeGallery = async({id, lang}) => {
  try {
    const {data} = await appInstance({lang}).delete(`/gallery/${id}`);
    return data;
  } catch(error) {
    throw errorHandler(error);
  }
}


