import styled from "@emotion/styled";
import uploadImage from "../../assets/images/uploader.svg";

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  input[type="file"] {
    display: none;
    width: 0.1px;
    height: 0.1px;
  }
`;

export const PreviewContainer = styled.div`
  position: relative;
  flex: 50%;
  user-select: none;
`;

export const PreviewWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 1;
`;

export const UploadInput = styled.div`
  /* width: fit-content; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  .upload-icon {
    cursor: pointer;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  /* max-width: 10vw; */
  max-height: 400px;
  object-fit: cover;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: #f0f2f5;
  padding: 10px;
  font-size: 15px;
  font-weight: 500;
  border: 1px dashed #058bec;
  cursor: pointer;
  height: ${(props) => props.height || "200px"};
  width: ${(props) => props.width || "100%"};
  flex-direction: column;

  img {
    width: 100px;
    height: 100px;
    margin-right: 10px;
  }

  h4 {
    margin-top: 10px;
    font-size: 13px;
    font-weight: 500;
    color: #3f3535;
  }

  &:hover {
    background-color: #e9edf3;
  }
`;
export const ImagePicker = ({ text, width, onClick, height }) => {
  return (
    <Button type="button" width={width} height={height} onClick={onClick}>
      <img src={uploadImage} alt="upload" />
      <h4>{text}</h4>
    </Button>
  );
};
export const EmptyContainer = styled.div`
  height: ${props => props.h || '220px'};
  width: ${props => props.w || '220px'};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: ${props => props.br || "5px"};
  border: 1px dashed #212121;
  user-select: none;
  &:hover {
    border-color: #007cff;
    span {
      color: #007cff;
    }
  }
`;

export const InputWIcon = styled.div`
  display: flex;
  user-select: none;
  flex-direction: row;
  border: 1px solid #21212150;
  border-radius: 5px;
  padding: 5px 10px;
  width: fit-content;
  cursor: pointer;
  margin-bottom: 10px;
`;

export const ButtonContainer = styled.div`
  padding: 10px 0;
`;
