import MDTypography from "../../../components/MDTypography";
import Tooltip from "@mui/material/Tooltip";
import MDButton from "../../../components/MDButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {convertToDate, convertToTimeUTC} from "../../../utils/timeConvert";
import MDBox from "../../../components/MDBox";
// import image from "../../../assets/images/default-blog.png";
import {Stack} from "@mui/material";


export const columns = ({handleConfirm, updateFood, t, language}) => {
  return [
    {
      Header: t("foods.tableHeader.name"),
      accessor: "name",
      align: "left",
      width: "100%",
      Cell: ({row}) => (
        <MDTypography variant="title1" fontWeight="medium">
          {row.original.name[language.code]}
        </MDTypography>
      ),
    },
    {
      Header: t("foods.tableHeader.category"),
      accessor: "category",
      align: "left",
      Cell: ({row}) => (
        <MDTypography variant="title1" fontWeight="medium">
          {row.original.food_category.name[language.code]}
        </MDTypography>
      ),
    },
    {
      Header: t("foods.tableHeader.createdAt"),
      accessor: "createdAt",
      align: "left",
      Cell: ({row}) => (
        <Stack flexDirection="column">
          <MDTypography variant="title1" fontWeight="medium">
            {convertToDate(row.original.created_at)}
          </MDTypography>
        </Stack>
      ),
    },
    {
      Header: t("foods.tableHeader.actions"),
      accessor: "actions",
      align: "left",
      isSticky: true,
      Cell: ({row}) => (
        <>
          <Tooltip title={t("buttons.update")}>
            <MDButton
              size="small"
              sx={{ marginRight: 1, maxWidth: 30, minWidth: 30, width: 30 }}
              color="warning"
              onClick={() => updateFood(row.original)}
            >
              <EditIcon />
            </MDButton>
          </Tooltip>
          <Tooltip title={t("buttons.delete")}>
            <MDButton
              size="small"
              sx={{maxWidth: 30, minWidth: 30, width: 30}}
              color="error"
              onClick={() => handleConfirm(row.original.id)}
            >
              <DeleteIcon/>
            </MDButton>
          </Tooltip>
        </>
      ),
    },
  ];
};
