import React, {useContext} from "react";
import {useNavigate} from "react-router-dom";
import PageLayout from "../../components/LayoutContainers/PageLayout";
import {Stack} from "@mui/material";
import Icon from "@mui/material/Icon";
import MDTypography from "../../components/MDTypography";
import MDButton from "../../components/MDButton";
import {LanguageContext} from "../../context/Language";


function NotFound(){
  const {t} = useContext(LanguageContext)
  const navigate = useNavigate();
  return (
    <PageLayout>
      <Stack display="flex" alignItems="center" justifyContent="center" height="100vh" p={5}>
        <Icon fontSize="large" color="error">error</Icon>
        <MDTypography mt={3} variant="h2">404</MDTypography>
        <MDTypography variant="h4">{t("notfound.main")}</MDTypography>
        <MDTypography mt={1} mb={3} fontWeight="regular" variant="caption">{t("notfound.message")}</MDTypography>
        <MDButton mt={5} onClick={() => navigate("/")} color={"info"}>{t("notfound.button")}</MDButton>
      </Stack>
    </PageLayout>
  );
}

export default NotFound;
