import React, {useContext, useEffect, useState} from "react";

// 3rd libraries
import Select from "react-select";
import {useFormik} from "formik";
import {toast} from "react-toastify";

// @mui

import Grid from "@mui/material/Grid";

//components
import MDInput from "../../../components/MDInput";
import MDTypography from "../../../components/MDTypography";
import MDButton from "../../../components/MDButton";
import {styles} from "../components/styles";

// services
import {createRequestForm, updateRequestForm} from "../../../services/requestFormService";

// context
import {LanguageContext} from "../../../context/Language";
import {Items} from "../components/items";
import {getRequestFormStates, updateRequestFormState} from "../../../services/requestFormStateService";


export const UpdateRequestForm = ({selectedRequestForm, closeModal, fetchData}) => {
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState(null);

  const {t, language} = useContext(LanguageContext);

  useEffect(() => {
    fetchRequestFromState();
  }, []);

  const fetchRequestFromState = async() => {
    try {
      const data = await getRequestFormStates({lang: language.code});
      setFormState(data);
    } catch(error) {
      console.log("err", error)
    }
  }

  const formik = useFormik({
    initialValues: {
      requestFormCategory: {label: selectedRequestForm.reservation_request_form_state?.name, value: selectedRequestForm.reservation_request_form_state.id},
    },
    onSubmit: async(values) => {
      const payload = {
        lang: language.code,
        id: selectedRequestForm.id,
        reservation_request_form_state: values.requestFormCategory.value,
      };
      try {
        await updateRequestFormState(payload);
        toast.success(t("updateRequestForm"));
        closeModal?.();
        fetchData?.()
      } catch(error) {
        console.log(error)
        toast.error(error || error.message);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid xs={12}><MDTypography ml={2} color="info" fontWeight="bold">{t("requestForm.update.title")}</MDTypography></Grid>
        <Grid item xs={12} md={12}>
          <Items name={t("items.user")} value={`${selectedRequestForm.name} ${selectedRequestForm.surname}`} />
          <Items name={t("items.email")} value={`${selectedRequestForm.email}`} />
          <Items name={t("items.phone")} value={`${selectedRequestForm.phone}`} />
        </Grid>
        <Grid item xs={12} md={12} mt={-1}>
          <Select
            fullWidth
            // placeholder={t("requestForms.inputs.requestFormCategory")}
            placeholder="State"
            styles={styles}
            isClearable={true}
            noOptionsMessage={({inputValue}) =>
              `${inputValue || t("requestForm.inputs.request_form_state")} ${t("requestForm.inputs.not_found")}.`
            }
            value={formik.values.requestFormCategory}
            onChange={(value) => formik.setFieldValue("requestFormCategory", value)}
            options={formState?.map((item) => {
              return {
                label: item.name,
                value: item.id,
              };
            })}
          />
        </Grid>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={7} md={8}>
            <MDButton
              disabled={loading}
              variant="contained"
              color="info"
              fullWidth
              type="submit"
            >
              {t("buttons.save")}
            </MDButton>
          </Grid>
          <Grid item xs={5} md={4}>
            <MDButton
              disabled={loading}
              variant="outlined"
              color="error"
              fullWidth
              onClick={closeModal}
            >
              {t("buttons.cancel")}
            </MDButton>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}
