import React, {useContext, useEffect, useState} from "react";

// components

import DashboardLayout from "../../components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../components/Navbars/DashboardNavbar";
import LoadingComponent from "../../components/LoadingComponent";
import MDBox from "../../components/MDBox";
import Card from "@mui/material/Card";
import MDTypography from "../../components/MDTypography";
import Grid from "@mui/material/Grid";

import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';

import {format} from 'date-fns';
import {DayPicker,} from 'react-day-picker';
import 'react-day-picker/dist/style.css';

//services
import {bookingCalendar, bookingOnDay, getRezervationBookings} from "../../services/bookingService";
import moment from "moment/moment";
import Select from "react-select";
import {styles} from "../foods/components/styles";
import {useFormik} from "formik";
import {createFood} from "../../services/foodService";
import {toast} from "react-toastify";
import {LanguageContext} from "../../context/Language";
import MDInput from "../../components/MDInput";


export const CreateBooking = () => {
  const [loading, setLoading] = useState(false);
  const [booking, setBooking] = useState([])
  const [bookingData, setBookingDate] = useState([])
  const [activeBooking, setActiveBooking] = useState([{data: [], loading: false}])

  const {t} = useContext(LanguageContext);

  const today = moment(new Date()).unix()

  const [selected, setSelected] = useState();

  let footer = <p>Please pick a day.</p>;
  if (selected) {
    footer = <p>You picked {format(selected, 'PP')}.</p>;
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const fetchBookingData = async() => {
      setActiveBooking(prevState => ({...prevState, loading: true}));
      try {
        const bookingDay = await bookingOnDay({today})
        setActiveBooking({data: bookingDay, loading: false})
      } catch(error) {
        console.log(error)
      } finally {
        setActiveBooking(prevState => ({...prevState, loading: false}));
      }
    }
    fetchBookingData();
  }, [selected]);

  const fetchData = async() => {
    setLoading(true)
    try {
      const data = await getRezervationBookings()
      const calender = await bookingCalendar({today})
      const bookingDay = await bookingOnDay({today})
      setBookingDate(calender)
      setBooking(data)
      setActiveBooking(bookingDay)
    } catch(error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  };

  const formik = useFormik({
    initialValues: {
      name: {tr: "", en: ""},
      foodCategory: [],
      bookingName: []
    },
    onSubmit: async(values) => {
      const payload = {
        name: {tr: values.name.tr, en: values.name.en},
        food_category: values.foodCategory.value
      };
      try {
        await createFood(payload);
        toast.success(t("foods.create.success"));
      } catch(error) {
        console.log(error)
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    },
  });

  if (loading) return <LoadingComponent/>;

  function isDisabled(day){
    const isoDate = day.toISOString().split("T")[0];
    const activeDay = bookingData.find((d) => d._id === isoDate);
    return !activeDay;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DashboardLayout>
        <DashboardNavbar/>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Card>
              <MDBox p={4}>
                <Grid item xs={12}>
                  <MDTypography
                    color="info"
                    variant="title2"
                    fontSize={16}
                    fontWeight="bold"
                  >
                     Yeni Rezervasyon Oluştur
                  </MDTypography>
                </Grid>
                <Grid item xs={12} mt={1}>
                  <DayPicker
                    mode="single"
                    selected={selected}
                    onSelect={setSelected}
                    footer={footer}
                    disabled={isDisabled}
                  />
                </Grid>
              </MDBox>
            </Card>
          </Grid>

          <Grid item xs={12} md={8}>
            <Card>
              <MDBox p={4}>
                <Grid item xs={12} md={9}>
                  <Select
                    fullWidth
                    placeholder={t("foods.inputs.foodCategory")}
                    styles={styles}
                    isClearable={true}
                    noOptionsMessage={({inputValue}) =>
                      `${inputValue || t("foods.inputs.food")} ${t("foods.inputs.notfound")}.`
                    }
                    value={formik.values.bookingName}
                    onChange={(value) => formik.setFieldValue("bookingName", value)}
                    options={activeBooking?.data?.map((item) => {
                      return {
                        label: item.name,
                        value: item.id,
                      };
                    })}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <MDInput
                    fullWidth
                    type="number"
                    label={t("booking.inputs.total_stock")}
                    name="total_stock"
                    value={formik.values.total_stock}
                    onChange={formik.handleChange}
                    error={formik.touched.total_stock && Boolean(formik.errors.total_stock)}
                    helperText={formik.touched.total_stock && formik.errors.total_stock}
                  />
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </DashboardLayout>
    </LocalizationProvider>
  )
}
