import { Tooltip } from "@mui/material";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import UnpublishedOutlinedIcon from "@mui/icons-material/UnpublishedOutlined";
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

export const User = ({ name, surname, email }) => (
  <MDBox display="flex" alignItems="center" lineHeight={1}>
    <MDBox lineHeight={1}>
      <MDTypography display="block" variant="button" fontWeight="medium">
        {`${name} ${surname}`}
      </MDTypography>
      <MDTypography variant="caption">{email}</MDTypography>
    </MDBox>
  </MDBox>
);

export const InstitutionAndCity = ({ institution, city }) => (
  <MDBox lineHeight={1} textAlign="left">
    <MDTypography
      display="block"
      variant="caption"
      color="text"
      fontWeight="medium"
    >
      {institution ?? "No Institution"}
    </MDTypography>
    <MDTypography variant="caption">{city ?? "No City"}</MDTypography>
  </MDBox>
);

export const Status = ({ status }) => (
  <MDBox display="flex" alignItems="center" lineHeight={1}>
    <MDBox lineHeight={1}>
      {status === "approve" ? (
        <Tooltip title="Approved" placement="top">
          <MDBox color="success">
            <CheckCircleOutlineOutlinedIcon fontSize="medium" />
          </MDBox>
        </Tooltip>
      ) : status === "pending-payment" ? (
        <Tooltip title="Pending Payment" placement="top">
          <MDBox color="warning">
            <PendingOutlinedIcon fontSize="medium" />
          </MDBox>
        </Tooltip>
      ) : status === "cancel" ? (
        <Tooltip title="Cancel" placement="top">
          <MDBox color="error">
            <CancelOutlinedIcon fontSize="medium" />
          </MDBox>
        </Tooltip>
      ) : (
        <MDBox color="info">
          <CheckCircleOutlineOutlinedIcon fontSize="medium" />
        </MDBox>
      )}
    </MDBox>
  </MDBox>
);
