import MDTypography from "../../../components/MDTypography";
import Tooltip from "@mui/material/Tooltip";
import MDButton from "../../../components/MDButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {convertToDate, convertToTimeUTC, convertToUtcWithoutTime} from "../../../utils/timeConvert";
import MDBox from "../../../components/MDBox";
// import image from "../../../assets/images/default-blog.png";
import {Chip, Stack} from "@mui/material";
import {Date} from "./Date";


export const columns = ({handleConfirm, updateBooking, t , language}) => {
  return [
    {
      Header: t("booking.tableHeader.image"),
      accessor: "image",
      align: "left",
      cWidth: "70px",
      Cell: ({row}) => (
        <img src={row.original.image} alt="booking" width={100} height={50} style={{borderRadius: 10}}/>
      ),
    },
    {
      Header: t("booking.tableHeader.name"),
      accessor: "name",
      align: "left",
      width: "100%",
      Cell: ({row}) => (
        <MDTypography variant="title1" fontWeight="medium">
          {row.original.name[language.code]}
        </MDTypography>
      ),
    },
    {
      Header: t("booking.tableHeader.bookingType"),
      accessor: "booking_type",
      align: "left",
      cWidth: "120px",
      Cell: ({row}) => (
        <Chip label={row.original.booking_type}/>
      ),
    },
    {
      Header: t("booking.tableHeader.available_count"),
      accessor: "available_count",
      align: "center",
      Cell: ({row}) => (
        <MDTypography variant="title2" color="text" fontWeight="medium">{row.original.available_count}</MDTypography>
      ),
    },
    {
      Header: t("booking.tableHeader.price"),
      accessor: "price",
      align: "left",
      Cell: ({row}) => (
        <MDTypography variant="title1" fontWeight="bold">{`€${row.original.price}`}</MDTypography>
      ),
    },
    {
      Header: t("booking.tableHeader.start_date"),
      accessor: "start_date",
      align: "left",
      Cell: ({row}) => (
        <Date
          start={`${convertToUtcWithoutTime(row.original.start_date)}`}
          end={`${convertToTimeUTC(row.original.start_date)} - ${convertToTimeUTC(row.original.end_date)}`}
        />
      ),
    },
    {
      Header: t("booking.tableHeader.actions"),
      accessor: "actions",
      align: "left",
      isSticky: true,
      Cell: ({row}) => (
        <>
          <Tooltip title={t("buttons.update")}>
            <MDButton
              size="small"
              sx={{marginRight: 1, maxWidth: 30, minWidth: 30, width: 30}}
              color="warning"
              onClick={() => updateBooking(row.original)}
            >
              <EditIcon/>
            </MDButton>
          </Tooltip>
          <Tooltip title={t("buttons.delete")}>
            <MDButton
              size="small"
              sx={{maxWidth: 30, minWidth: 30, width: 30}}
              color="error"
              onClick={() => handleConfirm(row.original.id)}
            >
              <DeleteIcon/>
            </MDButton>
          </Tooltip>
        </>
      ),
    },
  ];
};
