import DashboardLayout from "../../components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../components/Navbars/DashboardNavbar";
import {useFetchData} from "../../hooks/useFetchData";
import LoadingComponent from "../../components/LoadingComponent";
import React, {useContext, useEffect, useRef, useState} from "react";
import MDBox from "../../components/MDBox";
import Modal from "../../components/Modal";
import {UpdateCategory, UpdateFood} from "./update";
import {ConfirmModal} from "../../components/Modal/confirm/confirm";
import {toast} from "react-toastify";
import {getFoods, removeFood} from "../../services/foodService";
import {columns} from "./components/columns";
import NewTable from "../../components/Tables1/Table";
import {LanguageContext} from "../../context/Language";
import {removeGallery} from "../../services/galleryService";
import {AddAssets} from "../gallery/add";
import {CreateFood} from "./add/createFood";


export const Foods = () => {
  const [selectedFood, setSelectedFood] = useState([]);
  const [modal, setModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [selectedFoodId, setSelectedFoodId] = useState(null);
  const addFoodRef = useRef(null);
  const updateFoodRef = useRef(null);
  const deleteFoodRef = useRef(null);

  const is_crud = 1;

  const {t, language} = useContext(LanguageContext);

  const {
    loading,
    data,
    setData,
    limit,
    search,
    setSearch,
    setLimit,
    setPage,
    page,
    maxPage,
    fetchData,
  } = useFetchData(getFoods, [], language.code, is_crud);


  const addAssetModal = () => {
    addFoodRef.current.open();
    setModal(true);
  };
  const handleConfirm = (id) => {
    deleteFoodRef.current.open();
    setSelectedFoodId(id);
  };
  const updateFood = (currentFood) => {
    setSelectedFood(currentFood);
    updateFoodRef.current.open();
    setUpdateModal(true);
  };

  const deleteFood = async() => {
    try {
      await removeFood({id: selectedFoodId});
      const foodIndex = data.findIndex(
        (brand) => brand.id === selectedFoodId
      );
      const updatedFood = [...data];
      updatedFood.splice(foodIndex, 1);
      setData(updatedFood);
      toast.success(t("foods.delete.success"));
    } catch(error) {
      console.log(error)
      toast.error(error.message);
    }
  };
  if (loading) return <LoadingComponent/>;

  return (
    <DashboardLayout>
      <DashboardNavbar/>
      <Modal
        ref={updateFoodRef}
        closed={updateModal}
        children={
          <UpdateFood
            fetchData={() => fetchData()}
            selectedFood={selectedFood}
            closeModal={() => setUpdateModal((prev) => !prev)}
          />
        }
        customStyle={{
          width: {xs: "90%", md: "40%"},
          maxHeight: "90vh",
          overflow: "visible",
        }}
      />
      <Modal
        ref={addFoodRef}
        closed={modal}
        children={<CreateFood fetchData={() => fetchData()} closeModal={() => setModal((prev) => !prev)}/>}
        customStyle={{
          width: {xs: "90%", md: "40%"},
          maxHeight: "90vh",
          overflow: "visible",
        }}
      />
      <Modal
        ref={deleteFoodRef}
        children={
          <ConfirmModal
            closeModal={deleteFoodRef.current?.close}
            deleteFunc={() => deleteFood()}
            title={t("foods.deleteMessage")}
          />
        }
      />
      <MDBox p={2}>
        <NewTable
          title={t("foods.title")}
          limit={limit}
          setLimit={setLimit}
          setPage={setPage}
          page={page}
          maxPage={maxPage}
          prevFunc={() => setPage(page - 1)}
          nextFunc={() => setPage(page + 1)}
          columns={columns({handleConfirm, updateFood, t, language})}
          // search={search}
          // setSearch={setSearch}
          // canSearch={true}
          rows={data}
          addFunc={addAssetModal}
        />
      </MDBox>
    </DashboardLayout>
  )
}
