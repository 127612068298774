import {appInstance} from './http';
import errorHandler from "./errorHandler";
import {buildQuery} from "./buildQuery";

export const getOrders = async({limit, page, lang, is_crud, filtered, pagination = 1, search}) => {
  const query = buildQuery({limit, page, pagination, order_state: filtered, search});
  const queryParams = new URLSearchParams(query).toString();
  try {
    const {data} = await appInstance({lang}).get(`/order?${queryParams}`);
    return data;
  } catch(error) {
    throw errorHandler(error);
  }
}

export const orderCard = async ({ lang, payload }) => {
  try {
    const { data } = await appInstance({lang}).post('/order', payload);
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};

export const getOrderWToken = async ({ lang, token }) => {
  try {
    const { data } = await appInstance(lang).get(`/order/success/${token}`);
    return data;
  } catch (error) {
    throw errorHandler(error);
  }
};

export const triggerOrder = async({lang, id}) => {
  try {
    const {data} = await appInstance({lang}).put(`/order/${id}`);
    return data;
  } catch(error) {
    throw errorHandler(error);
  }
}
