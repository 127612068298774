import { Grid, styled } from "@mui/material";
import {useContext, useEffect, useState} from "react";
import MDButton from "../MDButton";
import MDTypography from "../MDTypography";
import {LanguageContext} from "../../context/Language";
import MDInput from "../MDInput";

export const CancelModal = ({ closeModal, deleteFunc, title, buttonTitle }) => {
  const abortController = new AbortController();
  const [password, setPassword] = useState("")

  const {t} = useContext(LanguageContext);

  const handleConfirm = () => {
    deleteFunc?.(abortController.signal);
    setTimeout(() => {
      closeModal?.();
    }, 200);
  };

  useEffect(() => {
    return () => {
      abortController.abort();
    };
  }, [abortController]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <MDTypography color="dark" variant="h4" fontWeight="bold">
          {title ?? "Silme işlemini onaylıyor musunuz? Bu işlem geri alınamaz!"}
        </MDTypography>
      </Grid>
      <Grid item container spacing={2}>
        <Grid item xs={8}>
          <MDButton
            fullWidth
            variant="outlined"
            color="error"
            onClick={handleConfirm}
          >
            {t("buttons.confirm")}
          </MDButton>
        </Grid>
        <Grid item xs={4}>
          <MDButton
            fullWidth
            variant="contained"
            color="info"
            onClick={() => closeModal?.()}
          >
            {t("buttons.cancel")}
          </MDButton>
        </Grid>
      </Grid>
    </Grid>
  );
};
