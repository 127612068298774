export const buildQuery = (
  params,
) => {
  let query = {};
  if (params.search) query.search = params.search;
  if (params.hasOwnProperty("limit")) query.limit = params.limit;
  if (params.hasOwnProperty("page")) query.page = params.page;
  if (params.hasOwnProperty("is_crud")) query.is_crud = params.is_crud;
  if (params.hasOwnProperty("filter")) query.filter = params.filter;
  if (params.hasOwnProperty("cancel")) query.cancel = params.cancel;
  if (params.hasOwnProperty("is_answered")) query.is_answered = params.is_answered;
  if (params.order_state) query.order_state = params.order_state;
  if (params.hasOwnProperty("pagination")) query.pagination = params.pagination;
  return query;
};
