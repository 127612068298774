import {appInstance} from './http';
import errorHandler from "./errorHandler";
import {buildQuery} from "./buildQuery";


// customer_type: ["person", "company"];
export const getRequestForm = async({limit, page, lang, pagination = 1, filter = 1,}) => {
  const query = buildQuery({limit, page, pagination});
  const queryParams = new URLSearchParams(query).toString();
  try {
    const {data} = await appInstance({lang}).get(`/reservation-request-form?${queryParams}`);
    return data;
  } catch(error) {
    throw errorHandler(error);
  }
}

export const createRequestForm = async(payload) => {
  try {
    const {data} = await appInstance({lang: payload.lang}).post("/reservation-request-form", payload);
    return data;
  } catch(error) {
    throw errorHandler(error);
  }
}

export const updateRequestForm = async(payload) => {
  try {
    const {data} = await appInstance({lang: payload.lang}).put("/reservation-request-form", payload);
    return data;
  } catch(error) {
    throw errorHandler(error);
  }
}

export const deleteRequestForm = async({lang, id}) => {
  try {
    const {data} = await appInstance(lang).delete(`/reservation-request-form/${id}`);
    return data;
  } catch(error) {
    throw errorHandler(error);
  }
}

export const removeRequestForm = async({lang, id}) => {
  try {
    const {data} = await appInstance(lang).patch(`/reservation-request-form/${id}`);
    return data;
  } catch(error) {
    throw errorHandler(error);
  }
}
