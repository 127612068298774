import styled from "styled-components";


export const SelectContainer = styled.select`
  width: 100px;
  height: 30px;
  outline: none;
  background-color: #3F4148;
  border-radius: 8px;
  /* padding: 0 10px; */
  user-select: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='8' viewBox='0 0 12 8' fill='none'%3E%3Cpath d='M9.87998 1.28957L5.99998 5.16957L2.11998 1.28957C1.72998 0.89957 1.09998 0.89957 0.70998 1.28957C0.31998 1.67957 0.31998 2.30957 0.70998 2.69957L5.29998 7.28957C5.68998 7.67957 6.31998 7.67957 6.70998 7.28957L11.3 2.69957C11.69 2.30957 11.69 1.67957 11.3 1.28957C10.91 0.90957 10.27 0.89957 9.87998 1.28957Z' fill='%23B0B7C3'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 18px center;

  padding: 0 16px 0 11px;

  color: #ffffff;
  font-family: 'Poppins';
  font-weight: 700;
  font-size: 14px;
  line-height: 29px;
  text-transform: uppercase;
  ${({$sx}) => $sx && $sx};
`;
