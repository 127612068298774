import React, {useContext, useState} from "react";

// 3rd libraries
import {toast} from "react-toastify";

// @mui
import {Divider, Grid} from "@mui/material";

// components
import MDBox from "../../../components/MDBox";
import Uploader from "../../../components/Uploader";
import MDButton from "../../../components/MDButton";
import MDTypography from "../../../components/MDTypography";

// services
import {createGallery} from "../../../services/galleryService";

// context
import {LanguageContext} from "../../../context/Language";


export const AddAssets = ({closeModal, fetchData}) => {

  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [fileList, setFiles] = useState([]);

  const {t} = useContext(LanguageContext);

  const handleSave = async() => {
    if (!image) return toast.error(t("gallery.fileUploadError"));
    const payload = {
      file: image,
      file_type: fileList[0].type === "video" ? "application/video" : `application/${fileList[0].extension}`,
    };
    try {
      setLoading(true);
      await createGallery(payload);
      toast.success(t("gallery.success"));
      closeModal?.();
      fetchData?.();
    } catch(error) {
      console.error(error);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <MDBox>
      <MDTypography color="info" fontSize={16} fontWeight="bold">
        {t("gallery.addNew")}
      </MDTypography>
      <Divider/>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Uploader
            fileList={fileList}
            onFileChange={(e) => setFiles(e)}
            text={t("upload.image")}
            setImage={setImage}
            loading={loading}
            setLoading={setLoading}
            width="100%"
            height="268px"
          />
        </Grid>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={7} md={8}>
            <MDButton
              disabled={loading}
              variant="contained"
              color="info"
              fullWidth
              onClick={handleSave}
            >
              {t("gallery.save")}
            </MDButton>
          </Grid>
          <Grid item xs={5} md={4}>
            <MDButton
              disabled={loading}
              variant="outlined"
              color="error"
              fullWidth
              onClick={closeModal}
            >
              {t("gallery.cancel")}
            </MDButton>
          </Grid>
        </Grid>
      </Grid>
    </MDBox>
  );
};
