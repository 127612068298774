import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthContext } from "../../context/auth";

const ProtectedRoute = ({
  redirectPath = "/authentication/sign-in",
  children,
}) => {
  let { authState } = useContext(AuthContext);
  if (!authState.token) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoute;
